import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import { today } from '../include/function'
import Error from '../shared/Error'
import Modal from '../shared/Modal'
import Spinner from '../shared/Spinner'
import ToastContext from '../context/ToastContext'
import Option from '../shared/Option'

const Shed = ({ user }) => {
    const { toast } = useContext(ToastContext)

    const [shedList, setShedList] = useState([])
    const [loading, setLoading] = useState(true)
    const [showFilter, setShowFilter] = useState('')

    const [fromDate, setFromDate] = useState(today())
    const [toDate, setToDate] = useState(today())
    const [locationId, setLocationId] = useState('')
    const [vendorId, setVendorId] = useState(user.role === "vendor" ? user.id : "")

    const [vendorList, setVendorList] = useState([])
    const [locationList, setLocationList] = useState([])

    const handleFilter = () => setShowFilter(true)

    const handleFromDate = (e) => {
        setFromDate(e.target.value)
    }
    const handleToDate = (e) => setToDate(e.target.value)

    const handleLocationId = (e) => {
        setLocationId(e.target.value)
    }
    const handleVendorId = (e) => {
        setVendorId(e.target.value)
        setLocationId('')
        setLocationList([])
    }

    const getLocationList = () => {
        api.get(`location/?vendor_id=${vendorId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setLocationList(data)
            }
        }).catch(error => {
            setLocationList([])
        })
    }
    const getVendorList = () => {
        api.get('vendor/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorList(data)
            }
        }).catch(error => {
            setVendorList([])
        })
    }


    const getShedList = () => {
        setLoading(true)
        api.get(`shed/?from_date=${fromDate}&to_date=${toDate}&location_id=${locationId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setShedList(data)
            }
        }).catch(error => {
            setShedList([])
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getShedList()
    }, [fromDate, toDate, locationId]) // eslint-disable-line

    useEffect(() => {
        getVendorList()
    }, [])

    useEffect(() => {
        if (vendorId !== "") {

            getLocationList()
        }
    }, [vendorId]) // eslint-disable-line

    const handleDelete = (id) => {
        api.delete(`shed/?id=${id}`, getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { message } = response.data
                toast(message)
                setShedList(prev => {
                    return prev.filter(shed => {
                        return shed.id !== id
                    })
                })
            }

        })

    }

    return <>
        {showFilter && <Modal setShowModal={setShowFilter}>
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" id="fromDate" onChange={handleFromDate} value={fromDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" id="toDate" onChange={handleToDate} value={toDate} />
                </div>
                {user.role === "admin" && <div className="form-group">
                    <label htmlFor="vendor">Vendor</label>
                    <select id="vendor" className="form-control" onChange={handleVendorId} value={vendorId}>
                        <option value="">All</option>
                        {vendorList.map((vendor, index) => {
                            return <Option
                                value={vendor.id}
                                text={vendor.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>}
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <select id="location" className="form-control" onChange={handleLocationId} value={locationId}>
                        <option value="">All</option>
                        {locationList.map((location, index) => {
                            return <Option
                                value={location.id}
                                text={location.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>

            </div>

        </Modal>}
        <div className="osahan-forgot">
            <div className="bg-light border-bottom p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="text-primary" to="/">
                        <i className="feather-chevron-left"></i>
                        <span>Back</span>
                    </Link>
                    {user.role === "admin" && <Link to="add" className="text-primary">
                        <span>Add</span>
                        <i className="feather-chevron-right" />
                    </Link>}
                </div>
            </div>
            <div className="p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className="m-0">Shed</h2>
                    <button className="btn btn-primary" onClick={handleFilter}>
                        <i className="feather-filter mr-1" />
                        <span>Filter</span>
                    </button>
                </div>
                <div className="overflow-auto mt-4">
                    {loading
                        ? <Spinner />
                        : shedList.length > 0
                            ? <table className="w-100 table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Description </th>
                                        <th>Location</th>
                                        {user.role === "admin" && <th className="text-center" colSpan={2}>Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shedList.map((shed, index) => {
                                        return <ShedBody
                                            id={shed.id}
                                            sNo={index + 1}
                                            date={shed.createdDate}
                                            name={shed.name}
                                            description={shed.description}
                                            location={shed.location.name}
                                            key={index}
                                            onDelete={() => handleDelete(shed.id)}
                                            user={user}
                                        />

                                    })}
                                </tbody>
                            </table>
                            : <Error errorText="NO RECORDS FOUND" />}
                </div>
            </div>
        </div>
    </>

}
const ShedBody = ({ id, sNo, date, name, description, location, onDelete, user }) => {

    return <tr>
        <td className="align-middle">{sNo}</td>
        <td className="align-middle">{date}</td>
        <td className="align-middle">{name}</td>
        <td className="align-middle">{description}</td>
        <td className="align-middle">{location}</td>
        {user.role === "admin" && <td className="align-middle">
            <div className="d-flex align-items-center">
                <Link to={`edit/${id}`} className="btn btn-primary btn-sm mx-1">Edit</Link>

            </div>
        </td>}
        {user.role === "admin" && <td className="align-middle">
            <div className="d-flex align-items-center">
                <button className="btn btn-primary btn-sm mx-1" onClick={onDelete}>Delete</button>
            </div>
        </td>}
    </tr>

}
export default Shed