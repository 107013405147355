import React from 'react'
import LocationForm from '../common/LocationForm'

const editLocation = () => {
    return <LocationForm
        type="EDIT"
    />
}

export default editLocation
