import React from 'react'
import { Link } from 'react-router-dom'
const Profile = ({ user }) => {
    return <div className="osahan-profile">
        <div className="bg-primary border-bottom px-3 pt-3 pb-5 d-flex align-items-center">
            <Link to="/" className="font-weight-bold m-0 text-white d-inline-block h3 mr-2">
                <i className="feather-arrow-left" />
            </Link>
            <h4 className="font-weight-bold m-0 text-white d-inline-block">Profile</h4>
        </div>
        <div className="p-3 osahan-profile">
            <div className="bg-white rounded shadow mt-n5">
                <div className="d-flex align-items-center border-bottom p-3">
                    <div className="left mr-3">
                        <h3 className="font-weight-bold m-0 text-dark">
                            <i className="feather-user bg-success text-white p-2 rounded-circle mr-2" />
                        </h3>
                    </div>
                    <div className="right">
                        <h6 className="mb-1 font-weight-bold">{user.name} <i className="feather-check-circle text-success"></i></h6>
                        <p className="text-muted m-0 small">{user.emailId}</p>
                    </div>
                </div>

            </div>

            <div className="bg-white rounded shadow mt-3 profile-details">


                <Link to="/change_password" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark"><i className="feather-edit bg-danger text-white p-2 rounded-circle mr-2"></i>Change Password</h6>

                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right"></i></h6>
                    </div>
                </Link>



            </div>
        </div>

    </div>

}

export default Profile