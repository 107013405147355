import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Option from '../shared/Option'
import ToastContext from '../context/ToastContext'

const ExpenseForm = ({ type }) => {
    const params = useParams()
    const { toast } = useContext(ToastContext)
    const [isLoading, setIsLoading] = useState(false)

    const [details, setDetails] = useState('')
    const [detailsError, setDetailsError] = useState('')

    const [amount, setAmount] = useState('')
    const [amountError, setAmountError] = useState('')

    const [batchId, setBatchId] = useState('')
    const [batchIdError, setBatchIdError] = useState('')

    const [batchList, setBatchList] = useState([])

    const handleDetails = (e) => {
        setDetailsError('')
        setDetails(e.target.value)
    }

    const handleBatchId = (e) => {
        setBatchIdError('')
        setBatchId(e.target.value)
    }

    const handleAmount = (e) => {
        setAmountError('')
        setAmount(e.target.value)
    }


    const handleExpense = (e) => {
        e.preventDefault()
        let error = false

        const data = {
            details: details,
            amount: amount,
            batchId: batchId,
        }

        if (data.details === "") {
            setDetailsError("Details required")
            error = true
        } else if (data.details.length > 100) {
            setDetailsError("Details should be within 100 characters")
            error = true
        }

        if (data.batchId === "") {
            setBatchIdError("Batch required")
            error = true
        }

        if (data.amount === "") {
            setAmountError("Amount required")
            error = true
        } else if (data.amount <= 0) {
            setAmountError("Amount must be valid number")
            error = true
        }

        if (error === false) {
            if (type === "ADD") {
                setIsLoading(true)
                api.post('expense/', data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                        setBatchId('')
                        setDetails('')
                        setAmount('')
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                }).finally(() => {
                    setIsLoading(false)
                })
            } else if (type === "EDIT") {
                setIsLoading(true)
                api.put(`expense/?id=${params.id}`, data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                }).finally(() => {
                    setIsLoading(false)
                })
            }

        }
    }
    const getBatchList = () => {
        api.get('batch/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setBatchList(data)
            }
        }).catch(error => {
            setBatchList([])

        })
    }
    const getExpense = () => {
        api.get(`expense/?id=${params.id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data

                setBatchId(data.batch.id)
                setDetails(data.details)
                setAmount(data.amount)
            }
        })
    }

    useEffect(() => {
        if (type === "EDIT") {
            getExpense()
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        getBatchList()
    }, [])// eslint-disable-line


    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/expense"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>{type === "ADD" ? "Add" : "Edit"} Expense</h2>
            <form className="mt-5 mb-4">

                <div className="form-group">
                    <label htmlFor="details">Details</label>
                    <textarea className="form-control" id="details" onChange={handleDetails} value={details} style={{ resize: "none" }} />
                    <span className="d-inline-block text-danger">{detailsError}</span>
                </div>

                <div className="form-group">
                    <label htmlFor="amount">Amount</label>
                    <input type="number" className="form-control" id="amount" onChange={handleAmount} value={amount} />
                    <span className="d-inline-block text-danger">{amountError}</span>
                </div>

                <div className="form-group">
                    <label htmlFor="batch">Batch</label>
                    <select id="batch" className="form-control" onChange={handleBatchId} value={batchId}>
                        <option value="">Select Batch</option>
                        {batchList.map((batch, index) => {
                            return <Option
                                value={batch.id}
                                text={batch.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {batchIdError}</span>
                </div>

                {isLoading
                    ? <button className="btn btn-lg btn-block btn-secondary" style={{ cursor: 'no-drop' }} onClick={e => e.preventDefault()}>{type === "ADD" ? "ADD" : "UPDATE"}</button>
                    : <button className="btn btn-lg btn-block btn-primary" onClick={handleExpense}>{type === "ADD" ? "ADD" : "UPDATE"}</button>}
            </form>
        </div >
    </div >
}
export default ExpenseForm
