import React, { useContext, useEffect, useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Spinner from '../shared/Spinner'
import Error from '../shared/Error'
import Modal from '../shared/Modal'
import Option from '../shared/Option'
import { today } from '../include/function'
import ToastContext from '../context/ToastContext'

const FeedStockHistory = ({ user }) => {
    const [loading, setLoading] = useState(true)
    const { toast } = useContext(ToastContext)
    const [showFilter, setShowFilter] = useState(false)

    const [feedStockHistoryList, setFeedStockHistoryList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [createdByList, setCreatedByList] = useState([])
    const [vendorList, setVendorList] = useState([])

    const [fromDate, setFromDate] = useState(today())
    const [toDate, setToDate] = useState(today())
    const [action, setAction] = useState('')
    const [locationId, setLocationId] = useState('')
    const [vendorId, setVendorId] = useState(user.role === 'vendor' ? user.id : '')
    const [createdBy, setCreatedBy] = useState('')

    const handleFilter = () => setShowFilter(true)

    const handleFromDate = (e) => {
        setFromDate(e.target.value)
    }
    const handleToDate = (e) => setToDate(e.target.value)

    const handleLocationId = (e) => {
        setLocationId(e.target.value)
    }
    const handleVendorId = (e) => {
        setVendorId(e.target.value)
        setLocationId('')
        setLocationList([])
    }

    const handleAction = (e) => {
        setAction(e.target.value)
    }

    const handleCreatedBy = (e) => {
        setCreatedBy(e.target.value)
    }

    const getUserList = () => {
        api.get('user/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setCreatedByList(data)
            }
        }).catch(error => {
            setCreatedByList([])
        })
    }

    const getLocationList = () => {
        api.get(`location/?vendor_id=${vendorId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setLocationList(data)

            }
        }).catch(error => {
            setLocationList([])
        })
    }
    const getVendorList = () => {
        api.get('vendor/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorList(data)

            }
        }).catch(error => {
            setVendorList([])
        })
    }
    const handleDelete = (id) => {

        api.delete(`feed_stock_history/?id=${id}`, getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { message } = response.data
                toast(message)
                setFeedStockHistoryList(prev => {
                    return prev.filter(FeedStockHistory => {
                        return FeedStockHistory.id !== id
                    })
                })
            }

        })

    }

    const getFeedStockHistoryList = () => {
        setLoading(true)
        api.get(`feed_stock_history/?from_date=${fromDate}&to_date=${toDate}&action=${action}&location_id=${locationId}&created_by=${createdBy}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setFeedStockHistoryList(data)
            }
        }).catch(error => {
            setFeedStockHistoryList([])
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getFeedStockHistoryList()
    }, [fromDate, toDate, action, locationId, createdBy]) // eslint-disable-line

    useEffect(() => {
        if (user.role !== "vendor") {
            getUserList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (user.role !== "vendor") {
            getVendorList()
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (vendorId !== "") {
            getLocationList()
        }
    }, [vendorId]) // eslint-disable-line

    return <>
        {showFilter && <Modal setShowModal={setShowFilter}>
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" id="fromDate" onChange={handleFromDate} value={fromDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" id="toDate" onChange={handleToDate} value={toDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="action">Action</label>
                    <select id="action" className="form-control" onChange={handleAction} value={action}>
                        <option value="">All</option>
                        <option value="credit">Credit</option>
                        <option value="debit">Debit</option>
                    </select>
                </div>
                {(user.role === "admin" || user.role === "supervisor") && <div className="form-group">
                    <label htmlFor="vendor">Vendor</label>
                    <select id="vendor" className="form-control" onChange={handleVendorId} value={vendorId}>
                        <option value="">All</option>
                        {vendorList.map((vendor, index) => {
                            return <Option
                                value={vendor.id}
                                text={vendor.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>}
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <select id="location" className="form-control" onChange={handleLocationId} value={locationId}>
                        <option value="">All</option>
                        {locationList.map((location, index) => {
                            return <Option
                                value={location.id}
                                text={location.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>
                {(user.role === "admin" || user.role === "supervisor") && <div className="form-group">
                    <label htmlFor="batch">Created By</label>
                    <select id="createdBy" className="form-control" onChange={handleCreatedBy} value={createdBy}>
                        <option value="">All</option>
                        {createdByList.map((user, index) => {
                            return <Option
                                value={user.id}
                                text={user.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>}
            </div>

        </Modal>}
        <div className="osahan-forgot">

            <div className="bg-light border-bottom p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="text-primary" to="/">
                        <i className="feather-chevron-left"></i>
                        <span>Back</span>
                    </Link>
                    {(user.role === "admin" || user.role === "supervisor") && <Link to="add" className="text-primary">
                        <span>Add</span>
                        <i className="feather-chevron-right" />
                    </Link>}
                </div>
            </div>
            <div className="p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className="m-0">Feed Stock History</h2>
                    <button className="btn btn-primary" onClick={handleFilter}>
                        <i className="feather-filter mr-1" />
                        <span>Filter</span>
                    </button>
                </div>
                <div className="overflow-auto mt-4">
                    {loading
                        ? <Spinner />
                        : feedStockHistoryList.length > 0
                            ? <table className="w-100 table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th className='text-truncate'>Feed Name</th>
                                        <th>Action </th>
                                        <th>Quantity</th>
                                        <th>Comment</th>
                                        <th className='text-truncate'>Total Quantity</th>
                                        <th> Location </th>
                                        <th className='text-truncate'>Created by</th>
                                        {(user.role === "admin" || user.role === "supervisor") && <th className='text-center' colSpan={2}>Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {feedStockHistoryList.map((FeedStockHistory, index) => {


                                        return <FeedStockHistoryBody

                                            id={FeedStockHistory.id}
                                            sNo={index + 1}
                                            date={FeedStockHistory.createdDate}
                                            feedName={FeedStockHistory.feedName}
                                            action={FeedStockHistory.action}
                                            quantity={FeedStockHistory.quantity}
                                            comment={FeedStockHistory.comment}
                                            totalQuantity={FeedStockHistory.totalQuantity}
                                            location={FeedStockHistory.location && FeedStockHistory.location.name}
                                            createdBy={FeedStockHistory.created.userName}
                                            key={index}
                                            onDelete={() => handleDelete(FeedStockHistory.id)}
                                            user={user}
                                        />

                                    })}
                                </tbody>
                            </table>
                            : <Error errorText="NO RECORDS FOUND" />}
                </div>
            </div>
        </div>
    </>
}

const FeedStockHistoryBody = ({ id, sNo, date, feedName, action, quantity, comment, totalQuantity, location, createdBy, onDelete, user }) => {



    return <tr>
        <td className="align-middle">{sNo}</td>
        <td className="align-middle text-truncate">{date}</td>
        <td className="align-middle">{feedName}</td>
        <td className="align-middle text-capitalize">{action}</td>
        <td className="align-middle">{quantity}kg</td>
        <td className="align-middle text-capitalize">{comment}</td>
        <td className="align-middle">{totalQuantity}kg</td>
        <td className="align-middle text-capitalize">{location}</td>
        < td className="align-middle text-truncate">{createdBy}</td>
        {(user.role === "admin" || user.role === "supervisor") && <td className="align-middle">
            <div className="d-flex align-items-center">
                <Link to={`edit/${id}`} className="btn btn-primary btn-sm mx-1">Edit</Link>

            </div>
        </td>}
        {(user.role === "admin" || user.role === "supervisor") && <td className="align-middle">
            <div className="d-flex align-items-start">
                <button className="btn btn-primary btn-sm mx-1" onClick={onDelete}>Delete</button>

            </div>
        </td>}

    </tr>
}


export default FeedStockHistory