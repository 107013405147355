import React, { useEffect, useState } from 'react'
import { nanoid } from 'nanoid'
import ToastContext from '../context/ToastContext'
import '../style/Toast.css'

const ToastContainer = ({ children }) => {
    const [messageList, setMessageList] = useState([])

    const setToast = (text) => {
        const key = nanoid()

        setMessageList(prev => {
            return [{
                key: key,
                text: text
            }, ...prev].slice(0, 6)
        })

        setTimeout(() => {
            removeToast(key)
        }, 5000)
    }

    const removeToast = (key) => {
        setMessageList(prev => {
            return prev.filter(p => p.key !== key)
        })
    }

    return <ToastContext.Provider value={{ toast: setToast }}>
        <div className="toast-container overflow-hidden">
            {messageList.map((message) => {
                return <Toast
                    key={message.key}
                    text={message.text}
                    removeToast={() => removeToast(message.key)}
                />
            })}
        </div>
        {children}
    </ToastContext.Provider>
}

const Toast = ({ text, removeToast }) => {
    const [animationClass, setAnimationClass] = useState('slide-in')

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setAnimationClass('slide-out')
        }, 4800)

        return () => clearTimeout(timeoutId)
    }, [])

    return <div className={`${animationClass} text-white rounded bg-dark rounded my-1 d-flex align-items-center justify-content-between`}>
        <span className="d-inline-block px-3 py-2">{text}</span>
        <i className="feather-x text-white d-inline-block px-3 py-2" style={{ cursor: 'pointer' }} onClick={removeToast} />
    </div>
}

export default ToastContainer