import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import { today } from '../include/function'
import Error from '../shared/Error'
import Modal from '../shared/Modal'
import Spinner from '../shared/Spinner'
import ToastContext from '../context/ToastContext'

const Buyer = ({ user }) => {
    const { toast } = useContext(ToastContext)

    const [buyerList, setBuyerList] = useState([])
    const [loading, setLoading] = useState(true)
    const [showFilter, setShowFilter] = useState('')

    const [fromDate, setFromDate] = useState(today())
    const [toDate, setToDate] = useState(today())
    const [status, setStatus] = useState('')


    const handleFilter = () => setShowFilter(true)

    const handleFromDate = (e) => {
        setFromDate(e.target.value)
    }
    const handleToDate = (e) => setToDate(e.target.value)

    const handleStatus = (e) => {
        setStatus(e.target.value)
    }


    const getBuyerList = () => {
        setLoading(true)
        api.get(`buyer/?from_date=${fromDate}&to_date=${toDate}&status=${status}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setBuyerList(data)
            }
        }).catch(error => {
            setBuyerList([])
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getBuyerList()
    }, [fromDate, toDate, status]) // eslint-disable-line

    const handleDelete = (id) => {
        api.delete(`buyer/?id=${id}`, getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { message } = response.data
                toast(message)
                setBuyerList(prev => {
                    return prev.filter(buyer => {
                        return buyer.id !== id
                    })
                })
            }

        })

    }


    return <>
        {showFilter && <Modal setShowModal={setShowFilter}>
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" id="fromDate" onChange={handleFromDate} value={fromDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" id="toDate" onChange={handleToDate} value={toDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <select id="status" className="form-control" onChange={handleStatus} value={status}>
                        <option value="">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                </div>
            </div>

        </Modal>}
        <div className="osahan-forgot">
            <div className="bg-light border-bottom p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="text-primary" to="/">
                        <i className="feather-chevron-left"></i>
                        <span>Back</span>
                    </Link>
                    {user.role === "admin" && <Link to="add" className="text-primary">
                        <span>Add</span>
                        <i className="feather-chevron-right" />
                    </Link>}
                </div>
            </div>
            <div className="p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className="m-0">Buyer</h2>
                    <button className="btn btn-primary" onClick={handleFilter}>
                        <i className="feather-filter mr-1" />
                        <span>Filter</span>
                    </button>
                </div>
                <div className="overflow-auto mt-4">
                    {loading
                        ? <Spinner />
                        : buyerList.length > 0
                            ? <table className="w-100 table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Description </th>
                                        <th>Address</th>
                                        <th>Contact</th>
                                        <th>Status</th>
                                        {user.role === "admin" && <th className='text-center' colSpan={2}>Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {buyerList.map((buyer, index) => {


                                        return <BuyerBody

                                            id={buyer.id}
                                            sNo={index + 1}
                                            date={buyer.createdDate}
                                            name={buyer.name}
                                            description={buyer.description}
                                            address={`${buyer.address1}, ${buyer.address2} ,${buyer.address3}`}
                                            contactNumber={buyer.contactNumber}
                                            emailId={buyer.emailId}
                                            status={buyer.status}
                                            key={index}
                                            onDelete={() => handleDelete(buyer.id)}
                                            user={user}
                                        />

                                    })}
                                </tbody>
                            </table>
                            : <Error errorText="NO RECORDS FOUND" />}
                </div>
            </div>
        </div>
    </>
}

const BuyerBody = ({ id, sNo, date, name, description, address, contactNumber, status, onDelete, emailId, user }) => {

    return <tr>
        <td className="align-middle">{sNo}</td>
        <td className="align-middle text-truncate">{date}</td>
        <td className="align-middle">{name}</td>
        <td className="align-middle">{description}</td>
        <td className="align-middle">{address}</td>
        <td className="align-middle">
            {contactNumber}
            <br />
            {emailId}
        </td>
        <td className="align-middle">
            <div className={`text-capitalize btn btn-${status === 'active' ? 'success' : 'danger'} `}>{status}</div>
        </td>
        {user.role === "admin" && <td className="align-middle">
            <div className="d-flex align-items-center">
                <Link to={`edit/${id}`} className="btn btn-primary btn-sm mx-1">Edit</Link>

            </div>
        </td>}
        {user.role === "admin" && <td className="align-middle">
            <div className="d-flex align-items-center">
                <button className="btn btn-primary btn-sm mx-1" onClick={onDelete}>Delete</button>
            </div>
        </td>}
    </tr>

}


export default Buyer