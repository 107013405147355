
import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Option from '../shared/Option'
import ToastContext from '../context/ToastContext'

const HealthForm = ({ type }) => {
    const { toast } = useContext(ToastContext)
    const params = useParams()
    const [isLoading, setIsLoading] = useState(false)

    const [dayCount, setDayCount] = useState('')
    const [dayCountError, setDayCountError] = useState('')

    const [disease, setDisease] = useState('')
    const [diseaseError, setDiseaseError] = useState('')

    const [treatment, setTreatment] = useState('')
    const [treatmentError, setTreatmentError] = useState('')
    const [comment, setComment] = useState('')
    const [commentError, setCommentError] = useState('')

    const [batchId, setBatchId] = useState('')
    const [batchIdError, setBatchIdError] = useState('')


    const [batchList, setBatchList] = useState([])

    const handleDayCount = (e) => {
        setDayCountError('')
        setDayCount(e.target.value)
    }

    const handleDisease = (e) => {
        setDiseaseError('')
        setDisease(e.target.value)
    }

    const handleTreatment = (e) => {
        setTreatmentError('')
        setTreatment(e.target.value)
    }
    const handleComment = (e) => {
        setCommentError('')
        setComment(e.target.value)
    }

    const handleBatchId = (e) => {
        setBatchIdError('')
        setBatchId(e.target.value)
    }

    const handleHealth = (e) => {
        e.preventDefault()

        let error = false

        const data = {
            dayCount: dayCount,
            disease: disease,
            treatment: treatment,
            comment: comment,
            batchId: batchId,
        }

        if (data.dayCount === "") {
            setDayCountError("DayCount required")
            error = true
        } else if (data.dayCount <= 0) {
            setDayCountError("DayCount must be a valid number")
            error = true
        }

        if (data.disease === "") {
            setDiseaseError("Disease required")
            error = true
        } else if (data.disease.length > 50) {
            setDiseaseError("Disease must be within 50 characters")
            error = true
        }

        if (data.treatment === "") {
            setTreatmentError("Treatment required")
            error = true
        } else if (data.treatment.length > 50) {
            setTreatmentError("Treatment must be within 50 characters")
            error = true
        }

        if (data.comment === "") {
            setCommentError("Comment required")
            error = true
        } else if (data.comment.length > 100) {
            setCommentError("Comment must be within 100 characters")
            error = true
        }

        if (data.batchId === "") {
            setBatchIdError("Batch required")
            error = true
        } else if (data.batchId <= 0) {
            setBatchIdError("Batch must be a valid number")
            error = true
        }

        if (error === false) {
            setIsLoading(true)
            if (type === "ADD") {
                api.post('health/', data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                        setDayCount('')
                        setDisease('')
                        setComment('')
                        setBatchId('')
                        setTreatment('')

                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                }).finally(() => {
                    setIsLoading(false)
                })
            } else if (type === "EDIT") {
                setIsLoading(true)
                api.put(`health/?id=${params.id}`, data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)

                }).finally(() => {
                    setIsLoading(false)
                })

            }
        }
    }
    const getHealth = () => {
        api.get(`health/?id=${params.id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setDayCount(data.dayCount)
                setDisease(data.disease)
                setTreatment(data.treatment)
                setComment(data.comment)
                setBatchId(data.batch.id)
            }
        })
    }

    const getBatchList = () => {
        api.get('batch/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setBatchList(data)
            }
        }).catch(error => {
            setBatchList([])
        })
    }

    useEffect(() => {
        if (type === "EDIT") {
            getHealth()
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        getBatchList()
    }, [])

    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/health"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>{type === "ADD" ? "Add" : "Edit"} Health</h2>
            <form className="mt-5 mb-4">

                <div className="form-group">
                    <label htmlFor="dayCount">Day Count</label>
                    <input type="text" id="dayCount" className="form-control" onChange={handleDayCount} value={dayCount} />
                    <span className="d-inline-block text-danger"> {dayCountError}</span>

                </div>

                <div className="form-group">
                    <label htmlFor="disease">Disease</label>
                    <input type="text" id="disease" className="form-control" onChange={handleDisease} value={disease} />
                    <span className="d-inline-block text-danger">{diseaseError}</span>

                </div>

                <div className="form-group">
                    <label htmlFor="treatment">Treatment</label>
                    <input type="text" className="form-control" id="treatment" onChange={handleTreatment} value={treatment} />
                    <span className="d-inline-block text-danger">{treatmentError}</span>
                </div>

                <div className="form-group">
                    <label htmlFor="comment">Comment</label>
                    <textarea id="comment" className="form-control" onChange={handleComment} value={comment} style={{ resize: "none" }} />
                    <span className="d-inline-block text-danger">{commentError}</span>

                </div>

                <div className="form-group">
                    <label htmlFor="batch">Batch</label>
                    <select id="batch" className="form-control" onChange={handleBatchId} value={batchId}>
                        <option value="">Select Batch</option>
                        {batchList.map((batch, index) => {
                            return <Option
                                value={batch.id}
                                text={batch.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {batchIdError}</span>
                </div>


                {isLoading
                    ? <button className="btn btn-lg btn-block btn-secondary" style={{ cursor: 'no-drop' }} onClick={e => e.preventDefault()}>{type === "ADD" ? "ADD" : "UPDATE"}</button>
                    : <button className="btn btn-lg btn-block btn-primary" onClick={handleHealth}>{type === "ADD" ? "ADD" : "UPDATE"}</button>}
            </form>
        </div >
    </div >
}
export default HealthForm
