
import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Option from '../shared/Option'
import ToastContext from '../context/ToastContext'

const BatchForm = ({ type }) => {
    const { toast } = useContext(ToastContext)
    const params = useParams()
    const [isLoading, setIsLoading] = useState(false)

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')

    const [description, setDescription] = useState('')
    const [descriptionError, setDescriptionError] = useState('')

    const [totalCount, setTotalCount] = useState('')
    const [totalCountError, setTotalCountError] = useState('')

    const [vendorId, setVendorId] = useState('')
    const [vendorIdError, setVendorIdError] = useState('')
    const [locationId, setLocationId] = useState('')
    const [locationIdError, setLocationIdError] = useState('')
    const [shedId, setShedId] = useState('')
    const [shedIdError, setShedIdError] = useState('')

    const [vendorList, setVendorList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [shedList, setShedList] = useState([])

    const handleName = (e) => {
        setNameError('')
        setName(e.target.value)
    }

    const handleShedId = (e) => {
        setShedIdError('')
        setShedId(e.target.value)
    }

    const handleVendorId = (e) => {
        setVendorIdError('')
        setLocationId('')
        setShedId('')
        setVendorId(e.target.value)

        setLocationList([])
        setShedList([])
    }

    const handleLocationId = (e) => {
        setLocationIdError('')
        setShedId('')
        setLocationId(e.target.value)

        setShedList([])
    }

    const handleDescription = (e) => {
        setDescriptionError('')
        setDescription(e.target.value)
    }

    const handleTotalCount = (e) => {
        setTotalCountError('')
        setTotalCount(e.target.value)
    }

    const handleBatch = (e) => {
        e.preventDefault()

        let error = false

        const data = {
            name: name,
            description: description,
            totalCount: totalCount,
            shedId: shedId,
            vendorId: vendorId,
            locationId: locationId
        }

        if (data.name === "") {
            setNameError("Name required")
            error = true
        } else if (data.name.length > 100) {
            setNameError("Name must be within 30 characters")
            error = true
        }

        if (data.description === "") {
            setDescriptionError("Description required")
            error = true
        } else if (data.description.length > 100) {
            setDescriptionError("Description must be within 100 characters")
            error = true
        }

        if (data.totalCount === "") {
            setTotalCountError("Total count required")
            error = true
        } else if (data.totalCount <= 0) {
            setTotalCountError("Total count must be valid number")
            error = true
        }

        if (data.shedId === "") {
            setShedIdError("Shed required")
            error = true
        }

        if (data.vendorId === "") {
            setVendorIdError("vendor required")
            error = true
        }

        if (data.locationId === "") {
            setLocationIdError("Location required")
            error = true
        }

        if (error === false) {
            setIsLoading(true)
            if (type === "ADD") {
                api.post('batch/', data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                        setName('')
                        setDescription('')
                        setVendorId('')
                        setLocationId('')
                        setShedId('')
                        setTotalCount('')

                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                }).finally(() => {
                    setIsLoading(false)
                })
            } else if (type === "EDIT") {
                setIsLoading(true)
                api.put(`batch/?id=${params.id}`, data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)

                }).finally(() => {
                    setIsLoading(false)
                })

            }
        }
    }

    const getBatch = () => {
        api.get(`batch/?id=${params.id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data

                setName(data.name)
                setDescription(data.description)
                setVendorId(data.vendor.id)
                setLocationId(data.location.id)
                setShedId(data.shed.id)
                setTotalCount(data.totalCount)
            }
        })
    }

    useEffect(() => {
        if (type === "EDIT") {
            getBatch()
        }
    }, []) // eslint-disable-line

    const getShedList = () => {
        api.get(`shed/?location_id=${locationId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setShedList(data)
            }
        }).catch(error => {
            setShedList([])

        })
    }

    const getVendorList = () => {
        api.get('vendor/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorList(data)
            }
        }).catch(error => {
            setVendorList([])
        })
    }
    const getLocationList = () => {
        api.get(`location/?vendor_id=${vendorId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setLocationList(data)
            }
        }).catch(error => {
            setLocationList([])

        })
    }

    useEffect(() => {
        if (locationId !== '') getShedList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId])

    useEffect(() => {
        if (vendorId !== '') getLocationList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorId])

    useEffect(() => {
        getVendorList()
    }, [])

    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/batch"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>{type === "ADD" ? "Add" : "Edit"} Batch</h2>
            <form className="mt-5 mb-4">

                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" className="form-control" onChange={handleName} value={name} />
                    <span className="d-inline-block text-danger"> {nameError}</span>

                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea id="description" className="form-control" onChange={handleDescription} value={description} style={{ resize: "none" }} />
                    <span className="d-inline-block text-danger">{descriptionError}</span>

                </div>


                <div className="form-group">
                    <label htmlFor="totalCount">Total Count</label>
                    <input type="number" className="form-control" id="totalCount" onChange={handleTotalCount} value={totalCount} />
                    <span className="d-inline-block text-danger">{totalCountError}</span>
                </div>

                <div className="form-group" >
                    <label htmlFor="vendor">Vendor</label>
                    <select id="vendor" className="form-control" onChange={handleVendorId} value={vendorId}>
                        <option value="">Select Vendor</option>
                        {vendorList.map((vendor, index) => {
                            return <Option
                                value={vendor.id}
                                text={vendor.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {vendorIdError}</span>
                </div>
                <div className="form-group" >
                    <label htmlFor="location">Location</label>
                    <select id="location" className="form-control" onChange={handleLocationId} value={locationId}>
                        <option value="">Select Location</option>
                        {locationList.map((location, index) => {
                            return <Option
                                value={location.id}
                                text={location.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {locationIdError}</span>

                </div>
                <div className="form-group" >
                    <label htmlFor="shed">Shed</label>
                    <select id="shed" className="form-control" onChange={handleShedId} value={shedId}>
                        <option value="">Select Shed</option>
                        {shedList.map((shed, index) => {
                            console.log(shedList);
                            return <Option
                                value={shed.id}
                                text={shed.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {shedIdError}</span>

                </div>


                {isLoading
                    ? <button className="btn btn-lg btn-block btn-secondary" style={{ cursor: 'no-drop' }} onClick={e => e.preventDefault()}>{type === "ADD" ? "ADD" : "UPDATE"}</button>
                    : <button className="btn btn-lg btn-block btn-primary" onClick={handleBatch}>{type === "ADD" ? "ADD" : "UPDATE"}</button>}
            </form>
        </div >
    </div >
}
export default BatchForm
