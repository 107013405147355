import React, { useEffect } from 'react'
import { removeLoggedInUser } from '../include/function'

const Logout = ({ setUser }) => {

    useEffect(() => {
        removeLoggedInUser()
        setTimeout(() => {
            setUser({})
        }, 500)
    }, []) // eslint-disable-line

    return <div className="p-3">Logging out. Please wait...</div>
}

export default Logout