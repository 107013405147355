import React from 'react'
import LocationForm from '../common/LocationForm'

const AddLocation = () => {
    return <LocationForm
        type="ADD"
    />
}

export default AddLocation
