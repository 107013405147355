import jsPDF from 'jspdf'
import 'jspdf-autotable'

export const pdf = () => {
    const doc = new jsPDF()

    doc.text("Delivery Report", 90, 10);
    doc.autoTable({ html: '#detailstable' })
    doc.autoTable({ html: '#pdftable' })
    doc.autoTable({ html: '#totaltable' })
    doc.save('table.pdf')
}