import React, { useContext, useEffect, useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Spinner from '../shared/Spinner'
import Error from '../shared/Error'
import Modal from '../shared/Modal'
import Option from '../shared/Option'
import { today } from '../include/function'
import ToastContext from '../context/ToastContext'
import SalesDetails from '../common/SalesDetails'



const Sales = ({ user }) => {


    const [loading, setLoading] = useState(true)
    const { toast } = useContext(ToastContext)
    const [showFilter, setShowFilter] = useState(false)
    const [showSalesDetails, setShowSalesDetails] = useState(false)

    const [salesList, setSalesList] = useState([])
    const [salesDetailsList, setSalesDetailsList] = useState([])
    const [buyerList, setBuyerList] = useState([])
    const [createdByList, setCreatedByList] = useState([])
    const [fromDate, setFromDate] = useState(today())
    const [toDate, setToDate] = useState(today())
    const [buyerId, setBuyerId] = useState('')
    const [createdBy, setCreatedBy] = useState('')

    const handleFilter = () => setShowFilter(true)

    const handleDetails = (salesDetails) => {
        setSalesDetailsList(salesDetails)
        setShowSalesDetails(true)
    }

    const handleFromDate = (e) => {
        setFromDate(e.target.value)
    }
    const handleToDate = (e) => setToDate(e.target.value)

    const handleBuyerId = (e) => {
        setBuyerId(e.target.value)
    }
    const handleCreatedBy = (e) => {
        setCreatedBy(e.target.value)
    }
    const getUserList = () => {
        api.get('user/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setCreatedByList(data)
            }
        }).catch(error => {
            setCreatedByList([])
        })
    }
    const getBuyerList = () => {
        api.get('buyer/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setBuyerList(data)

            }
        }).catch(error => {
            setBuyerList([])
        })
    }

    const handleDelete = (id) => {

        api.delete(`sales/?id=${id}`, getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { message } = response.data
                toast(message)
                setSalesList(prev => {
                    return prev.filter(sales => {
                        return sales.id !== id
                    })
                })
            }

        })

    }

    const getSalesList = () => {
        setLoading(true)
        api.get(`sales/?from_date=${fromDate}&to_date=${toDate}&buyer_id=${buyerId}&created_by=${createdBy}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setSalesList(data)
            }
        }).catch(error => {
            setSalesList([])
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getSalesList()
    }, [fromDate, toDate, buyerId, createdBy]) // eslint-disable-line

    useEffect(() => {
        getUserList()
    }, [])

    useEffect(() => {
        getBuyerList()
    }, []) // eslint-disable-line

    return <>
        {showFilter && <Modal setShowModal={setShowFilter} >
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" id="fromDate" onChange={handleFromDate} value={fromDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" id="toDate" onChange={handleToDate} value={toDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="buyer">Buyer</label>
                    <select id="buyer" className="form-control" onChange={handleBuyerId} value={buyerId}>
                        <option value="">All</option>
                        {buyerList.map((buyer, index) => {
                            return <Option
                                value={buyer.id}
                                text={buyer.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="batch">Created By</label>
                    <select id="createdBy" className="form-control" onChange={handleCreatedBy} value={createdBy}>
                        <option value="">All</option>
                        {createdByList.map((user, index) => {
                            return <Option
                                value={user.id}
                                text={user.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>
            </div>
        </Modal>}

        {showSalesDetails && <Modal setShowModal={setShowSalesDetails}>
            <SalesDetails salesDetails={salesDetailsList} />
        </Modal>}

        <div className="osahan-forgot">

            <div className="bg-light border-bottom p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="text-primary" to="/">
                        <i className="feather-chevron-left"></i>
                        <span>Back</span>
                    </Link>
                    {(user.role === "admin" || user.role === "supervisor" || user.role === "marketing") && <Link to="add" className="text-primary">
                        <span>Add</span>
                        <i className="feather-chevron-right" />
                    </Link>}
                </div>
            </div>
            <div className="p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className="m-0">Sales</h2>
                    <button className="btn btn-primary" onClick={handleFilter}>
                        <i className="feather-filter mr-1" />
                        <span>Filter</span>
                    </button>
                </div>

                <div className="overflow-auto mt-4">
                    {loading
                        ? <Spinner />
                        : salesList.length > 0
                            ? <table className="w-100 table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Buyer</th>
                                        <th>Total Count </th>
                                        <th>Total Weight </th>
                                        <th>Rate1 </th>
                                        <th>Rate2</th>
                                        <th>Rate3</th>
                                        <th>Final Rate</th>
                                        <th>Total Amount</th>
                                        <th>Vehicle Name</th>
                                        <th>Vehicle Number</th>
                                        <th>Driver Name</th>
                                        <th>Driver Number</th>
                                        <th>Created by</th>
                                        {(user.role === "admin" || user.role === "supervisor" || user.role === "marketing") && <th className='text-center' colSpan={2}>Action</th>}
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {salesList.map((sales, index) => {
                                        return <SalesBody
                                            id={sales.id}
                                            sNo={index + 1}
                                            date={sales.createdDate}
                                            buyer={sales.buyer.name}
                                            totalCount={sales.totalCount}
                                            totalWeight={sales.totalWeight}
                                            rate1={sales.rate1}
                                            rate2={sales.rate2}
                                            rate3={sales.rate3}
                                            finalRate={sales.finalRate}
                                            totalAmount={sales.totalAmount}
                                            vehicleName={sales.vehicleName}
                                            vehicleNumber={sales.vehicleNumber}
                                            driverName={sales.driverName}
                                            driverContactNumber={sales.driverContactNumber}
                                            createdBy={sales.created.userName}
                                            key={index}
                                            onDelete={() => handleDelete(sales.id)}
                                            onDetails={() => handleDetails(sales.salesDetails)}
                                            user={user}
                                        />

                                    })}
                                </tbody>
                            </table>
                            : <Error errorText="NO RECORDS FOUND" />}
                </div>
            </div>
        </div>
    </>
}

const SalesBody = ({ id, sNo, date, buyer, totalCount, totalWeight, rate1, rate2, rate3, finalRate, totalAmount, vehicleName, vehicleNumber, driverName, driverContactNumber, createdBy, onDelete, onDetails, user }) => {
    return <tr>
        <td className="align-middle">{sNo}</td>
        <td className="align-middle text-truncate">{date}</td>
        <td className="align-middle">{buyer}</td>
        <td className="align-middle">{totalCount}</td>
        <td className="align-middle">{totalWeight}</td>
        <td className="align-middle">&#8377;{Number(rate1).toFixed(2)}</td>
        <td className="align-middle">&#8377;{Number(rate2).toFixed(2)}</td>
        <td className="align-middle">&#8377;{Number(rate3).toFixed(2)}</td>
        <td className="align-middle">&#8377;{Number(finalRate).toFixed(2)}</td>
        <td className="align-middle">&#8377;{Number(totalAmount).toFixed(2)}</td>
        <td className="align-middle">{vehicleName}</td>
        <td className="align-middle">{vehicleNumber}</td>
        <td className="align-middle">{driverName}</td>
        <td className="align-middle">{driverContactNumber}</td>
        <td className="align-middle">{createdBy}</td>
        {(user.role === "admin" || user.role === "supervisor" || user.role === "marketing") && <td className="align-middle">
            <div className="d-flex align-items-center">
                <Link to={`edit/${id}`} className="btn btn-primary btn-sm mx-1">Edit</Link>

            </div>
        </td>}
        {(user.role === "admin" || user.role === "supervisor" || user.role === "marketing") && <td className="align-middle">
            <div className="d-flex align-items-start">
                <button className="btn btn-primary btn-sm mx-1" onClick={onDelete}>Delete</button>
            </div>
        </td>}
        {(user.role === "admin" || user.role === "supervisor" || user.role === "marketing") && <td className="align-middle">
            <div className="d-flex align-items-start">
                <button className="btn btn-primary btn-sm mx-1" onClick={onDetails}>Details</button>
            </div>
        </td>}

    </tr>
}


export default Sales