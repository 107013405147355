import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import AddBatch from './component/AddBatch'
import AddHealth from './component/AddHealth'
import AddLocation from './component/AddLocation'
import AddExpense from './component/AddExpense'
import Batch from './component/Batch'
import Health from './component/Health'
import Location from './component/Location'
import ChangePassword from './component/ChangePassword'
import EditBatch from './component/EditBatch'
import EditHealth from './component/EditHealth'
import EditLocation from './component/EditLocation'
import EditExpense from './component/EditExpense'
import Expense from './component/Expense'
import AddBuyer from './component/AddBuyer'
import AddBuyerOrder from './component/AddBuyerOrder'
import AddFeed from './component/AddFeed'
import AddSales from './component/AddSales'
import AddShed from './component/AddShed'
import AddUser from './component/AddUser'
import Buyer from './component/Buyer'
import BuyerOrder from './component/BuyerOrder'
import EditBuyer from './component/EditBuyer'
import EditBuyerOrder from './component/EditBuyerOrder'
import EditFeed from './component/EditFeed'
import EditSales from './component/EditSales'
import EditShed from './component/EditShed'
import EditUser from './component/EditUser'
import Feed from './component/Feed'
import Home from './component/Home'
import Login from './component/Login'
import Logout from './component/Logout'
import Profile from './component/Profile'
import Growth from './component/Growth'
import AddGrowth from './component/AddGrowth'
import EditGrowth from './component/EditGrowth'
import { getLoggedInUser, isEmptyObj } from './include/function'
import Sales from './component/Sales'
import Shed from './component/Shed'
import User from './component/User'
import AddFeedStockHistory from './component/AddFeedStockHistory'
import EditFeedStockHistory from './component/EditFeedStockHistory'
import FeedStockHistory from './component/FeedStockHistory'
import AddVendor from './component/AddVendor'
import EditVendor from './component/EditVendor'
import Vendor from './component/Vendor'
import Mortality from './component/Mortality'
import AddMortality from './component/AddMortality'
import EditMortality from './component/EditMortality'
import VendorLogin from './component/VendorLogin'
import './style/App.css'
import Report from './component/Report'

const App = () => {
    const [user, setUser] = useState(getLoggedInUser())

    return <Router>
        <Routes>
            <Route path='/' element={isEmptyObj(user) ? <Navigate to='/login' /> : <Home user={user} />} />

            <Route path='batch'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "vendor") ? <Batch user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin") ? <AddBatch /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin") ? <EditBatch /> : <Navigate to='/' />} />
            </Route>
            <Route path='expense'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor" || user.role === "vendor") ? <Expense user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <AddExpense /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <EditExpense /> : <Navigate to='/' />} />
            </Route>

            <Route path='health'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor" || user.role === "vendor") ? <Health user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <AddHealth /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <EditHealth /> : <Navigate to='/' />} />
            </Route>
            <Route path='mortality'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor" || user.role === "vendor") ? <Mortality user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <AddMortality /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <EditMortality /> : <Navigate to='/' />} />
            </Route>

            <Route path='location'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "vendor") ? <Location user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin") ? <AddLocation /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin") ? <EditLocation /> : <Navigate to='/' />} />
            </Route>
            <Route path='growth'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin"
                    || user.role === "supervisor" || user.role === "vendor") ? <Growth user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <AddGrowth /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <EditGrowth /> : <Navigate to='/' />} />
            </Route>

            <Route path='feed'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor" || user.role === "vendor") ? <Feed user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <AddFeed /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <EditFeed /> : <Navigate to='/' />} />
            </Route>
            <Route path='feed_stock_history'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor" || user.role === "vendor") ? <FeedStockHistory user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <AddFeedStockHistory /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <EditFeedStockHistory /> : <Navigate to='/' />} />
            </Route>
            <Route path='expense'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor" || user.role === "vendor") ? <Expense user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <AddExpense /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "supervisor") ? <EditExpense /> : <Navigate to='/' />} />
            </Route>
            <Route path='buyer'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin") ? <Buyer user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin") ? <AddBuyer /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin") ? <EditBuyer /> : <Navigate to='/' />} />
            </Route>
            <Route path='buyer_order'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "marketing") ? <BuyerOrder user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "marketing") ? <AddBuyerOrder /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "marketing") ? <EditBuyerOrder /> : <Navigate to='/' />} />
            </Route>

            <Route path='sales'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "marketing" || user.role === "supervisor") ? <Sales user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "marketing" || user.role === "supervisor") ? <AddSales /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "marketing" || user.role === "supervisor") ? <EditSales /> : <Navigate to='/' />} />
            </Route>
            <Route path='shed'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin" || user.role === "vendor") ? <Shed user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin") ? <AddShed /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin") ? <EditShed /> : <Navigate to='/' />} />

            </Route>
            <Route path='vendor'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin") ? <Vendor user={user} /> : <Navigate to='/' />} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin") ? <AddVendor /> : <Navigate to='/' />} />
                <Route path='edit/:id' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === "admin") ? <EditVendor /> : <Navigate to='/' />} />

            </Route>

            <Route path='user'>
                <Route path='' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === 'admin' ? <User user={user} /> : <Navigate to='/' />)} />
                <Route path='add' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === 'admin' ? <AddUser /> : <Navigate to='/' />)} />
                <Route path='edit/:userId' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === 'admin' ? <EditUser /> : <Navigate to='/' />)} />
            </Route>
            <Route path='report' element={isEmptyObj(user) ? <Navigate to='/' /> : (user.role === 'admin' ? <Report /> : <Navigate to='/' />)} />

            <Route path='login' element={isEmptyObj(user) ? <Login setUser={setUser} /> : <Navigate to='/' />} />
            <Route path='vendor_login' element={isEmptyObj(user) ? <VendorLogin setUser={setUser} /> : <Navigate to='/' />} />
            <Route path='profile' element={isEmptyObj(user) ? <Navigate to='/' /> : <Profile user={user} />} />
            <Route path='change_password' element={isEmptyObj(user) ? <Navigate to='/' /> : <ChangePassword userId={user.userId} role={user.role} />} />
            <Route path='logout' element={isEmptyObj(user) ? <Navigate to='/' /> : <Logout setUser={setUser} />} />
        </Routes>
    </Router>
}

export default App
