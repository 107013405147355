import React, { useState } from "react"
import { Link } from "react-router-dom"
import '../style/Modal.css'

const Modal = ({ children, setShowModal }) => {
    const [popDown, setPopDown] = useState(false)

    const handleClose = (e) => {
        e.preventDefault()
        setPopDown(true)
        setTimeout(() => {
            setShowModal(false)
        }, 200)
    }

    return <div className="custom-modal-wrapper d-flex align-items-center justify-content-center">
        <div className={`custom-modal-body rounded p-0 ${popDown ? 'custom-modal-pop-down' : ''}`} style={{ maxHeight: '90%', width: '90%', overflowX: "hidden", overflowY: "auto" }}>
            <div>{children}</div>
            <hr className="my-0" />
            <div className="text-center d-flex align-items-center justify-content-center py-3">
                <Link to="#" className="font-weight-bold" onClick={handleClose}>Close</Link>
            </div>
        </div>
    </div >
}

export default Modal