import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import { today } from '../include/function'
import Error from '../shared/Error'
import Modal from '../shared/Modal'
import Spinner from '../shared/Spinner'
import ToastContext from '../context/ToastContext'
import Option from '../shared/Option'

const BuyerOrder = ({ user }) => {
    const { toast } = useContext(ToastContext)

    const [buyerOrderList, setBuyerOrderList] = useState([])
    const [loading, setLoading] = useState(true)
    const [showFilter, setShowFilter] = useState('')

    const [fromDate, setFromDate] = useState(today())
    const [toDate, setToDate] = useState(today())
    const [buyerId, setBuyerId] = useState('')
    const [createdBy, setCreatedBy] = useState('')

    const [createdByList, setCreatedByList] = useState([])
    const [buyerList, setBuyerList] = useState([])

    const handleFilter = () => setShowFilter(true)

    const handleFromDate = (e) => {
        setFromDate(e.target.value)
    }

    const handleToDate = (e) => setToDate(e.target.value)
    const handleBuyerId = (e) => {
        setBuyerId(e.target.value)
    }

    const handleCreatedBy = (e) => {
        setCreatedBy(e.target.value)
    }
    const getUserList = () => {
        api.get('user/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setCreatedByList(data)
            }
        }).catch(error => {
            setCreatedByList([])
        })
    }

    const getBuyerList = () => {
        api.get('buyer/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setBuyerList(data)
            }
        }).catch(error => {
            setBuyerList([])
        })
    }

    const getBuyerOrderList = () => {
        setLoading(true)
        api.get(`buyer_order/?from_date=${fromDate}&to_date=${toDate}&buyer_id=${buyerId}&created_by=${createdBy}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setBuyerOrderList(data)
            }
        }).catch(error => {
            setBuyerOrderList([])
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getBuyerOrderList()
    }, [fromDate, toDate, buyerId, createdBy]) // eslint-disable-line

    useEffect(() => {
        getUserList()
    }, [])

    useEffect(() => {
        getBuyerList()
    }, [])

    const handleDelete = (id) => {
        api.delete(`buyer_order/?id=${id}`, getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { message } = response.data
                toast(message)
                setBuyerOrderList(prev => {
                    return prev.filter(buyerOrder => {
                        return buyerOrder.id !== id
                    })
                })
            }

        })

    }


    return <>
        {showFilter && <Modal setShowModal={setShowFilter}>
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" id="fromDate" onChange={handleFromDate} value={fromDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" id="toDate" onChange={handleToDate} value={toDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="buyerId">Buyer</label>
                    <select id="buyerId" className="form-control" onChange={handleBuyerId} value={buyerId}>
                        <option value="">All</option>
                        {buyerList.map((buyer, index) => {
                            return <Option
                                value={buyer.id}
                                text={buyer.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="createdBy">Created By</label>
                    <select id="createdBy" className="form-control" onChange={handleCreatedBy} value={createdBy}>
                        <option value="">All</option>
                        {createdByList.map((user, index) => {
                            return <Option
                                value={user.id}
                                text={user.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>
            </div>

        </Modal>}
        <div className="osahan-forgot">
            <div className="bg-light border-bottom p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="text-primary" to="/">
                        <i className="feather-chevron-left"></i>
                        <span>Back</span>
                    </Link>
                    {(user.role === "admin" || user.role === "marketing") && <Link to="add" className="text-primary">
                        <span>Add</span>
                        <i className="feather-chevron-right" />
                    </Link>}
                </div>
            </div>

            <div className="p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className="m-0">Buyer Order</h2>
                    <button className="btn btn-primary" onClick={handleFilter}>
                        <i className="feather-filter mr-1" />
                        <span>Filter</span>
                    </button>
                </div>
                <div className="overflow-auto mt-4">
                    {loading
                        ? <Spinner />
                        : buyerOrderList.length > 0
                            ? <table className="w-100 table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th className="text-truncate"> Buyer Name</th>
                                        <th>Count</th>
                                        <th className="text-truncate">Average Weight</th>
                                        <th className="text-truncate">Delivery date</th>
                                        <th className="text-truncate">Created by</th>
                                        {(user.role === "admin" || user.role === "marketing") && <th className='text-center' colSpan={2}>Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {buyerOrderList.map((buyerOrder, index) => {


                                        return <BuyerOrderBody

                                            id={buyerOrder.id}
                                            sNo={index + 1}
                                            date={buyerOrder.createdDate}
                                            buyerName={buyerOrder.buyer.name}
                                            count={buyerOrder.count}
                                            avgWeight={buyerOrder.avgWeight}
                                            deliveryDate={buyerOrder.deliveryDate}
                                            createdBy={buyerOrder.created.userName}
                                            key={index}
                                            onDelete={() => handleDelete(buyerOrder.id)}
                                            user={user}
                                        />

                                    })}
                                </tbody>
                            </table>
                            : <Error errorText="NO RECORDS FOUND" />}
                </div>
            </div>
        </div>
    </>
}

const BuyerOrderBody = ({ id, sNo, date, buyerName, count, avgWeight, deliveryDate, createdBy, onDelete, user }) => {

    return <tr>
        <td className="align-middle">{sNo}</td>
        <td className="align-middle text-truncate">{date}</td>
        <td className="align-middle">{buyerName}</td>
        <td className="align-middle">{count}</td>
        <td className="align-middle">{avgWeight} kg</td>
        <td className="align-middle text-truncate">{deliveryDate}</td>
        < td className="align-middle text-truncate">{createdBy}</td>
        {(user.role === "admin" || user.role === "marketing") && <td className="align-middle">
            <div className="d-flex align-items-center">
                <Link to={`edit/${id}`} className="btn btn-primary btn-sm mx-1">Edit</Link>

            </div>
        </td>}
        {(user.role === "admin" || user.role === "marketing") && <td className="align-middle">
            <div className="d-flex align-items-center">
                <button className="btn btn-primary btn-sm mx-1" onClick={onDelete}>Delete</button>
            </div>
        </td>}
    </tr>

}


export default BuyerOrder