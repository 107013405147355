import React from 'react'
import Error from '../shared/Error'

const SalesDetails = ({ salesDetails }) => {
    return <div className="osahan-forgot">
        <div className="p-4">
            <h2 className="m-0">Sales Details</h2>
            <div className="overflow-auto mt-4">
                {salesDetails.length > 0
                    ? <table className="w-100 table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Seq No</th>
                                <th>No.Cages </th>
                                <th>Empty Weight </th>
                                <th>Loaded Weight</th>
                                <th>Cage Bird Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {salesDetails.map((sd, index) => {
                                return <SalesBody
                                    sNo={index + 1}
                                    seqNo={sd.seqNo}
                                    numCages={sd.numCages}
                                    emptyWeight={sd.emptyWeight}
                                    loadedWeight={sd.loadedWeight}
                                    cageBirdCount={sd.cageBirdCount}
                                    key={index}
                                />

                            })}
                        </tbody>
                    </table>
                    : <Error errorText="NO RECORDS FOUND" />}
            </div>
        </div>
    </div>
}

const SalesBody = ({ sNo, seqNo, numCages, emptyWeight, loadedWeight, cageBirdCount }) => {
    return <tr>
        <td className="align-middle">{sNo}</td>
        <td className="align-middle">{seqNo}</td>
        <td className="align-middle">{numCages}</td>
        <td className="align-middle">{emptyWeight}</td>
        <td className="align-middle">{loadedWeight}</td>
        <td className="align-middle">{cageBirdCount}</td>
    </tr>
}


export default SalesDetails