
import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Option from '../shared/Option'
import ToastContext from '../context/ToastContext'

const FeedStockHistoryForm = ({ type }) => {
    const { toast } = useContext(ToastContext)
    const params = useParams()
    const [isLoading, setIsLoading] = useState(false)

    const [feedName, setFeedName] = useState('')
    const [feedNameError, setFeedNameError] = useState('')
    const [action, setAction] = useState('')
    const [actionError, setActionError] = useState('')

    const [quantity, setQuantity] = useState('')
    const [quantityError, setQuantityError] = useState('')

    const [comment, setComment] = useState('')
    const [commentError, setCommentError] = useState('')
    const [totalQuantity, setTotalQuantity] = useState('')
    const [totalQuantityError, setTotalQuantityError] = useState('')
    const [locationId, setLocationId] = useState('')
    const [locationIdError, setLocationIdError] = useState('')
    const [vendorId, setVendorId] = useState('')
    const [vendorIdError, setVendorIdError] = useState('')

    const [locationList, setLocationList] = useState([])
    const [vendorList, setVendorList] = useState([])

    const handleFeedName = (e) => {
        setFeedNameError('')
        setFeedName(e.target.value)
    }

    const handleAction = (e) => {
        setActionError('')
        setAction(e.target.value)
    }

    const handleQuantity = (e) => {
        setQuantityError('')
        setQuantity(e.target.value)
    }

    const handleComment = (e) => {
        setCommentError('')
        setComment(e.target.value)
    }
    const handleLocationId = (e) => {
        setLocationIdError('')
        setLocationId(e.target.value)
    }
    const handleVendorId = (e) => {
        setLocationId('')
        setVendorIdError('')
        setVendorId(e.target.value)
        setLocationList([])
    }
    const handleTotalQuantity = (e) => {
        setTotalQuantityError('')
        setTotalQuantity(e.target.value)
    }

    const handleFeedStockHistory = (e) => {
        e.preventDefault()

        let error = false

        const data = {
            feedName: feedName,
            action: action,
            quantity: quantity,
            comment: comment,
            totalQuantity: totalQuantity,
            locationId: locationId,
            vendorId: vendorId
        }

        if (data.feedName === "") {
            setFeedNameError("Title required")
            error = true
        } else if (data.feedName.length > 30) {
            setFeedNameError("Feed name must be within 30 characters")
            error = true
        }
        if (data.action === "") {
            setActionError("Action required")
            error = true
        }

        if (data.comment === "") {
            setCommentError("Comment required")
            error = true
        } else if (data.comment.length > 100) {
            setCommentError("Comment must be within 100 characters")
            error = true
        }

        if (data.quantity === "") {
            setQuantityError("Quantity required")
            error = true
        } else if (data.quantity <= 0) {
            setQuantityError("Quantity must be valid number")
            error = true
        }
        if (data.totalQuantity === "") {
            setTotalQuantityError("Total Quantity required")
            error = true
        } else if (data.totalQuantity <= 0) {
            setTotalQuantityError("Total Quantity must be valid number")
            error = true
        }
        if (data.locationId === "") {
            setLocationIdError("Location required")
            error = true
        }
        if (data.vendorId === "") {
            setVendorIdError("Vendor required")
            error = true
        }
        if (error === false) {
            setIsLoading(true)
            if (type === "ADD") {
                api.post('feed_stock_history/', data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                        setFeedName('')
                        setAction('')
                        setQuantity('')
                        setComment('')
                        setTotalQuantity('')
                        setLocationId('')
                        setVendorId('')
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                }).finally(() => {
                    setIsLoading(false)
                })
            } else if (type === "EDIT") {
                setIsLoading(true)
                api.put(`feed_stock_history/?id=${params.id}`, data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)

                }).finally(() => {
                    setIsLoading(false)
                })

            }
        }

    }
    const getFeedStockHistory = () => {
        api.get(`feed_stock_history/?id=${params.id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setFeedName(data.feedName)
                setAction(data.action)
                setQuantity(data.quantity)
                setComment(data.comment)
                setTotalQuantity(data.totalQuantity)
                setLocationId(data.location.id)
                setVendorId(data.vendor.id)
            }
        })
    }

    useEffect(() => {
        if (type === "EDIT") {
            getFeedStockHistory()
        }
    }, []) // eslint-disable-line

    const getLocationList = () => {
        api.get(`location/?vendor_id=${vendorId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setLocationList(data)
            }
        }).catch(error => {
            setLocationList([])

        })
    }
    const getVendorList = () => {
        api.get('vendor/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorList(data)
            }
        }).catch(error => {
            setVendorList([])

        })
    }
    useEffect(() => {
        if (vendorId !== "") {
            getLocationList()
        }
    }, [vendorId]) // eslint-disable-line


    useEffect(() => {
        getVendorList()
    }, []) // eslint-disable-line

    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/feed_stock_history"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>{type === "ADD" ? "Add" : "Edit"} Feed Stock History</h2>
            <form className="mt-5 mb-4">
                <div className="form-group">
                    <label htmlFor="feedName">Feed Name</label>
                    <input type="text" id="feedName" className="form-control" onChange={handleFeedName} value={feedName} />
                    <span className="d-inline-block text-danger">{feedNameError}</span>

                </div>
                <div className="form-group">
                    <label htmlFor="action">Action</label>
                    <select id="action" className="form-control" onChange={handleAction} value={action}>
                        <option value="">Select action</option>
                        <option value="credit">Credit</option>
                        <option value="debit">Debit</option>
                    </select>
                    <span className="d-inline-block text-danger"> {actionError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="quantity">Quantity</label>
                    <input type="number" id="quantity" className="form-control" onChange={handleQuantity} value={quantity} />
                    <span className="d-inline-block text-danger">{quantityError}</span>

                </div>
                <div className="form-group">
                    <label htmlFor="comment">Comment</label>
                    <input type="text" id="comment" className="form-control" onChange={handleComment} value={comment} />
                    <span className="d-inline-block text-danger">{commentError}</span>

                </div>
                <div className="form-group">
                    <label htmlFor="totalQuantity">Total Quantity</label>
                    <input type="number" className="form-control" id="totalQuantity" onChange={handleTotalQuantity} value={totalQuantity} />
                    <span className="d-inline-block text-danger">{totalQuantityError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="vendor">Vendor</label>
                    <select id="vendor" className="form-control" onChange={handleVendorId} value={vendorId}>
                        <option value="">Select Vendor</option>
                        {vendorList.map((vendor, index) => {
                            return <Option
                                value={vendor.id}
                                text={vendor.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {vendorIdError}</span>

                </div>
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <select id="location" className="form-control" onChange={handleLocationId} value={locationId}>
                        <option value="">Select location</option>
                        {locationList.map((location, index) => {
                            return <Option
                                value={location.id}
                                text={location.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {locationIdError}</span>

                </div>
                {isLoading
                    ? <button className="btn btn-lg btn-block btn-secondary" style={{ cursor: 'no-drop' }} onClick={e => e.preventDefault()}>{type === "ADD" ? "ADD" : "UPDATE"}</button>
                    : <button className="btn btn-lg btn-block btn-primary" onClick={handleFeedStockHistory}>{type === "ADD" ? "ADD" : "UPDATE"}</button>}
            </form>
        </div >
    </div >
}
export default FeedStockHistoryForm
