
import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Option from '../shared/Option'
import ToastContext from '../context/ToastContext'
import SalesDetailsForm from './SalesDetailsForm'
import { nanoid } from 'nanoid'
import { isValidPhoneNumber } from '../include/function'

const getInitialSalesDetails = () => {
    return {
        data: {
            seqNo: '',
            numCages: '',
            emptyWeight: '',
            loadedWeight: '',
            cageBirdCount: '',
        },
        error: {
            seqNoError: '',
            numCagesError: '',
            emptyWeightError: '',
            loadedWeightError: '',
            cageBirdCountError: '',
        },
        key: nanoid(),
    }
}

const SalesForm = ({ type }) => {
    const { toast } = useContext(ToastContext)
    const params = useParams()
    const [isLoading, setIsLoading] = useState(false)

    const [salesDetails, setSalesDetails] = useState([getInitialSalesDetails()])
    const [vendorId, setVendorId] = useState('')
    const [vendorIdError, setVendorIdError] = useState('')
    const [shedId, setShedId] = useState('')
    const [shedIdError, setShedIdError] = useState('')
    const [buyerId, setBuyerId] = useState('')
    const [buyerIdError, setBuyerIdError] = useState('')

    const [rate1, setRate1] = useState('')
    const [rate1Error, setRate1Error] = useState('')
    const [rate2, setRate2] = useState('')
    const [rate2Error, setRate2Error] = useState('')
    const [rate3, setRate3] = useState('')
    const [rate3Error, setRate3Error] = useState('')
    const [finalRate, setFinalRate] = useState('')
    const [finalRateError, setFinalRateError] = useState('')
    const [vehicleName, setVehicleName] = useState('')
    const [vehicleNameError, setVehicleNameError] = useState('')
    const [vehicleNumber, setVehicleNumber] = useState('')
    const [vehicleNumberError, setVehicleNumberError] = useState('')
    const [driverName, setDriverName] = useState('')
    const [driverNameError, setDriverNameError] = useState('')
    const [driverContactNumber, setDriverContactNumber] = useState('')
    const [driverContactNumberError, setDriverContactNumberError] = useState('')

    const [buyerList, setBuyerList] = useState([])
    const [vendorList, setVendorList] = useState([])
    const [shedList, setShedList] = useState([])
    const handleVendorId = (e) => {
        setVendorIdError('')
        setVendorId(e.target.value)
    }
    const handleShedId = (e) => {
        setShedIdError('')
        setShedId(e.target.value)
    }
    const handleBuyerId = (e) => {
        setBuyerIdError('')
        setBuyerId(e.target.value)
    }
    const handleRate1 = (e) => {
        setRate1Error('')
        setRate1(e.target.value)
    }
    const handleRate2 = (e) => {
        setRate2Error('')
        setRate2(e.target.value)
    }
    const handleRate3 = (e) => {
        setRate3Error('')
        setRate3(e.target.value)
    }
    const handleFinalRate = (e) => {
        setFinalRateError('')
        setFinalRate(e.target.value)
    }

    const handleVehicleName = (e) => {
        setVehicleNameError('')
        setVehicleName(e.target.value)
    }
    const handleVehicleNumber = (e) => {
        setVehicleNumberError('')
        setVehicleNumber(e.target.value)
    }
    const handleDriverName = (e) => {
        setDriverNameError('')
        setDriverName(e.target.value)
    }
    const handleDriverContactNumber = (e) => {
        setDriverContactNumberError('')
        setDriverContactNumber(e.target.value)
    }

    const handleSeqNo = (key, value) => {
        setSalesDetails(prev => {
            const currentChangeObjectIndex = prev.findIndex(sd => sd.key === key)
            const updatedSalesDetails = [...prev]
            updatedSalesDetails[currentChangeObjectIndex].data.seqNo = value
            updatedSalesDetails[currentChangeObjectIndex].error.seqNoError = ''
            return updatedSalesDetails
        })
    }

    const handleNumCages = (key, value) => {
        setSalesDetails(prev => {
            const currentChangeObjectIndex = prev.findIndex(sd => sd.key === key)
            const updatedSalesDetails = [...prev]
            updatedSalesDetails[currentChangeObjectIndex].data.numCages = value
            updatedSalesDetails[currentChangeObjectIndex].error.numCagesError = ''
            console.log(updatedSalesDetails);
            return updatedSalesDetails
        })
    }
    const handleEmptyWeight = (key, value) => {
        setSalesDetails(prev => {
            const currentChangeObjectIndex = prev.findIndex(sd => sd.key === key)
            const updatedSalesDetails = [...prev]
            updatedSalesDetails[currentChangeObjectIndex].data.emptyWeight = value
            updatedSalesDetails[currentChangeObjectIndex].error.emptyWeightError = ''
            return updatedSalesDetails
        })
    }
    const handleLoadedWeight = (key, value) => {
        setSalesDetails(prev => {
            const currentChangeObjectIndex = prev.findIndex(sd => sd.key === key)
            const updatedSalesDetails = [...prev]
            updatedSalesDetails[currentChangeObjectIndex].data.loadedWeight = value
            updatedSalesDetails[currentChangeObjectIndex].error.loadedWeightError = ''
            return updatedSalesDetails
        })
    }
    const handleCageBirdCount = (key, value) => {
        setSalesDetails(prev => {
            const currentChangeObjectIndex = prev.findIndex(sd => sd.key === key)
            const updatedSalesDetails = [...prev]
            updatedSalesDetails[currentChangeObjectIndex].data.cageBirdCount = value
            updatedSalesDetails[currentChangeObjectIndex].error.cageBirdCountError = ''
            return updatedSalesDetails
        })
    }
    const handleSales = (e) => {
        e.preventDefault()

        let error = false

        const data = {
            vendorId: vendorId,
            shedId: shedId,
            buyerId: buyerId,
            rate1: rate1,
            rate2: rate2,
            rate3: rate3,
            finalRate: finalRate,
            vehicleName: vehicleName,
            vehicleNumber: vehicleNumber,
            driverName: driverName,
            driverContactNumber: driverContactNumber,
            salesDetails: salesDetails
        }
        if (type === "ADD") {
            if (data.vendorId === "") {
                setVendorIdError("Vendor Required")
                error = true
            }
            if (data.shedId === "") {
                setShedIdError("Shed Required")
                error = true
            }

            if (data.buyerId === "") {
                setBuyerIdError("Buyer Required")
                error = true
            }
        }
        if (data.rate1 === "") {
            setRate1Error("Rate1 Required")
            error = true
        } else if (data.rate1 <= 0) {
            setRate1Error("Rate1 must be a valid number")
            error = true
        }
        if (data.rate2 === "") {
            setRate2Error("Rate2 Required")
            error = true
        } else if (data.rate2 <= 0) {
            setRate2Error("Rate2 must be a valid number")
            error = true
        }
        if (data.rate2 === "") {
            setRate2Error("Rate2 Required")
            error = true
        } else if (data.rate2 <= 0) {
            setRate2Error("Rate2 must be a valid number")
            error = true
        }
        if (data.rate3 === "") {
            setRate3Error("Rate3 Required")
            error = true
        } else if (data.rate3 <= 0) {
            setRate3Error("Rate3 must be a valid number")
            error = true
        }
        if (data.finalRate === "") {
            setFinalRateError("Final Rate Required")
            error = true
        } else if (data.finalRate <= 0) {
            setFinalRateError("Final Rate must be a valid number")
            error = true
        }
        if (data.vehicleName === "") {
            setVehicleNameError("Vehicle Name required")
            error = true
        } else if (data.vehicleName.length > 50) {
            setVehicleNameError("Vehicle name must be within 50 characters")
            error = true
        }
        if (data.vehicleNumber === "") {
            setVehicleNumberError("Vehicle Number required")
            error = true
        } else if (data.vehicleNumber.length > 20) {
            setVehicleNumberError("Vehicle Number must be within 20 characters")
            error = true
        }
        if (data.driverName === "") {
            setDriverNameError("Driver Name required")
            error = true
        } else if (data.vehicleName.length > 50) {
            setVehicleNameError("Vehicle name must be within 50 characters")
            error = true
        }
        if (data.driverContactNumber === "") {
            setDriverContactNumberError("Driver Contact Number Required")
            error = true
        } else if (!isValidPhoneNumber(data.driverContactNumber)) {
            setDriverContactNumberError("Driver Contact Number must be a valid one")
            error = true
        }

        data.salesDetails.map(sd => {
            if (sd.data.seqNo === "") {
                setSalesDetails(prev => {
                    const currentChangeObjectIndex = prev.findIndex(p => p.key === sd.key)
                    const updatedSalesDetails = [...prev]
                    updatedSalesDetails[currentChangeObjectIndex].error.seqNoError = 'Seq no required'
                    return updatedSalesDetails
                })
                error = true
            } else if (sd.data.seqNo <= 0) {
                setSalesDetails(prev => {
                    const currentChangeObjectIndex = prev.findIndex(p => p.key === sd.key)
                    const updatedSalesDetails = [...prev]
                    updatedSalesDetails[currentChangeObjectIndex].error.seqNoError = 'Seq no must be a positive number'
                    return updatedSalesDetails
                })
                error = true
            }

            if (sd.data.numCages === "") {
                setSalesDetails(prev => {
                    const currentChangeObjectIndex = prev.findIndex(p => p.key === sd.key)
                    const updatedSalesDetails = [...prev]
                    updatedSalesDetails[currentChangeObjectIndex].error.numCagesError = 'No.of.cages required'
                    return updatedSalesDetails
                })
                error = true
            } else if (sd.data.numCages <= 0) {
                setSalesDetails(prev => {
                    const currentChangeObjectIndex = prev.findIndex(p => p.key === sd.key)
                    const updatedSalesDetails = [...prev]
                    updatedSalesDetails[currentChangeObjectIndex].error.numCagesError = 'Num .of.cages must be a positive number'
                    return updatedSalesDetails
                })
                error = true
            }

            if (sd.data.emptyWeight === "") {
                setSalesDetails(prev => {
                    const currentChangeObjectIndex = prev.findIndex(p => p.key === sd.key)
                    const updatedSalesDetails = [...prev]
                    updatedSalesDetails[currentChangeObjectIndex].error.emptyWeightError = 'Empty Weight required'
                    return updatedSalesDetails
                })
                error = true
            } else if (sd.data.emptyWeight <= 0) {
                setSalesDetails(prev => {
                    const currentChangeObjectIndex = prev.findIndex(p => p.key === sd.key)
                    const updatedSalesDetails = [...prev]
                    updatedSalesDetails[currentChangeObjectIndex].error.emptyWeightError = 'Empty Weight must be a positive number'
                    return updatedSalesDetails
                })
                error = true
            }

            if (sd.data.loadedWeight === "") {
                setSalesDetails(prev => {
                    const currentChangeObjectIndex = prev.findIndex(p => p.key === sd.key)
                    const updatedSalesDetails = [...prev]
                    updatedSalesDetails[currentChangeObjectIndex].error.loadedWeightError = 'Loaded Weight required'
                    return updatedSalesDetails
                })
                error = true
            } else if (sd.data.loadedWeight <= 0) {
                setSalesDetails(prev => {
                    const currentChangeObjectIndex = prev.findIndex(p => p.key === sd.key)
                    const updatedSalesDetails = [...prev]
                    updatedSalesDetails[currentChangeObjectIndex].error.loadedWeightError = 'Loaded Weight must be a positive number'
                    return updatedSalesDetails
                })
                error = true
            }

            if (sd.data.cageBirdCount === "") {
                setSalesDetails(prev => {
                    const currentChangeObjectIndex = prev.findIndex(p => p.key === sd.key)
                    const updatedSalesDetails = [...prev]
                    updatedSalesDetails[currentChangeObjectIndex].error.cageBirdCountError = 'Cage Bird Count  required'
                    return updatedSalesDetails
                })
                error = true
            } else if (sd.data.cageBirdCount <= 0) {
                setSalesDetails(prev => {
                    const currentChangeObjectIndex = prev.findIndex(p => p.key === sd.key)
                    const updatedSalesDetails = [...prev]
                    updatedSalesDetails[currentChangeObjectIndex].error.cageBirdCountError = 'Cage Bird Count must be a positive number'
                    return updatedSalesDetails
                })
                error = true
            }

            return true
        })

        if (error === false) {

            const salesData = {
                ...data,
                salesDetails: data.salesDetails.map(sd => {
                    return sd.data
                })
            }

            setIsLoading(true)
            if (type === "ADD") {
                api.post('sales/', salesData, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                        setVendorId('')
                        setShedId('')
                        setBuyerId('')
                        setRate1('')
                        setRate2('')
                        setRate3('')
                        setFinalRate('')
                        setVehicleName('')
                        setVehicleNumber('')
                        setDriverName('')
                        setDriverContactNumber('')
                        setSalesDetails([getInitialSalesDetails()])
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                }).finally(() => {
                    setIsLoading(false)
                })
            } else if (type === "EDIT") {
                setIsLoading(true)
                api.put(`sales/?id=${params.id}`, salesData, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)

                }).finally(() => {
                    setIsLoading(false)
                })

            }
        }
    }

    const getSales = () => {
        api.get(`sales/?id=${params.id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorId(data.vendor.id)
                setShedId(data.shed.id)
                setBuyerId(data.buyer.id)
                setRate1(data.rate1)
                setRate2(data.rate2)
                setRate3(data.rate3)
                setFinalRate(data.finalRate)
                setVehicleName(data.vehicleName)
                setVehicleNumber(data.vehicleNumber)
                setDriverName(data.driverName)
                setDriverContactNumber(data.driverContactNumber)
                setSalesDetails(data.salesDetails.map(sd => {
                    return {
                        data: sd,
                        error: getInitialSalesDetails().error,
                        key: nanoid()
                    }
                }))
            }
        })
    }

    useEffect(() => {
        if (type === "EDIT") {
            getSales()
        }
    }, []) // eslint-disable-line

    const getBuyerList = () => {
        api.get('buyer/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setBuyerList(data)
            }
        }).catch(error => {

        })
    }

    useEffect(() => {
        getBuyerList()
    }, []) // eslint-disable-line
    const getVendorList = () => {
        api.get('vendor/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorList(data)
            }
        }).catch(error => {

        })
    }

    useEffect(() => {
        getVendorList()
    }, []) // eslint-disable-line
    const getShedList = () => {
        api.get('shed/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setShedList(data)
            }
        }).catch(error => {

        })
    }

    useEffect(() => {
        getShedList()
    }, []) // eslint-disable-line

    const handleSalesDetails = () => {
        setSalesDetails(prev => {
            return [...prev, getInitialSalesDetails()]
        })
    }

    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/sales"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>{type === "ADD" ? "Add" : "Edit"} Sales</h2>
            <form className="mt-5 mb-4">
                <div className="form-group">
                    <label htmlFor="vendor">Vendor</label>
                    <select id="vendor" className="form-control" onChange={handleVendorId} value={vendorId}>
                        <option value="">Select Vendor</option>
                        {vendorList.map((vendor, index) => {
                            return <Option
                                value={vendor.id}
                                text={vendor.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {vendorIdError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="shed">Shed</label>
                    <select id="shed" className="form-control" onChange={handleShedId} value={shedId}>
                        <option value="">Select Shed</option>
                        {shedList.map((shed, index) => {
                            return <Option
                                value={shed.id}
                                text={shed.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {shedIdError}</span>

                </div>

                <div className="form-group">
                    <label htmlFor="buyer">Buyer</label>
                    <select id="buyer" className="form-control" onChange={handleBuyerId} value={buyerId}>
                        <option value="">Select buyer</option>
                        {buyerList.map((buyer, index) => {
                            return <Option
                                value={buyer.id}
                                text={buyer.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {buyerIdError}</span>

                </div>
                <div className="form-group">
                    <label htmlFor="rate1">Rate1</label>
                    <input type="number" className="form-control" id="rate1" onChange={handleRate1} value={rate1} />
                    <span className="d-inline-block text-danger">{rate1Error}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="rate2">Rate2</label>
                    <input type="number" className="form-control" id="rate2" onChange={handleRate2} value={rate2} />
                    <span className="d-inline-block text-danger">{rate2Error}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="rate3">Rate3</label>
                    <input type="number" className="form-control" id="rate3" onChange={handleRate3} value={rate3} />
                    <span className="d-inline-block text-danger">{rate3Error}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="finalRate">Final Rate</label>
                    <input type="number" className="form-control" id="finalRate" onChange={handleFinalRate} value={finalRate} />
                    <span className="d-inline-block text-danger">{finalRateError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="vehicleName">Vehicle Name</label>
                    <input type="text" className="form-control" id="vehicleName" onChange={handleVehicleName} value={vehicleName} />
                    <span className="d-inline-block text-danger">{vehicleNameError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="vehicleNumber">VehicleNumber</label>
                    <input type="text" className="form-control" id="vehicleNumber" onChange={handleVehicleNumber} value={vehicleNumber} />
                    <span className="d-inline-block text-danger">{vehicleNumberError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="driverName">Driver Name</label>
                    <input type="text" className="form-control" id="driverName" onChange={handleDriverName} value={driverName} />
                    <span className="d-inline-block text-danger">{driverNameError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="driverContactNumber">Driver Contact Number</label>
                    <input type="number" className="form-control" id="driverContactNumber" onChange={handleDriverContactNumber} value={driverContactNumber} />
                    <span className="d-inline-block text-danger">{driverContactNumberError}</span>
                </div>
                <div className="d-flex align-items-center">
                    <button type='button' className="btn btn-primary btn-sm mx-1 " onClick={handleSalesDetails}>Add Sales Details</button>

                </div>
            </form>
            {salesDetails.map(sd => {
                return <SalesDetailsForm
                    seqNo={sd.data.seqNo}
                    numCages={sd.data.numCages}
                    emptyWeight={sd.data.emptyWeight}
                    loadedWeight={sd.data.loadedWeight}
                    cageBirdCount={sd.data.cageBirdCount}
                    seqNoError={sd.error.seqNoError}
                    numCagesError={sd.error.numCagesError}
                    emptyWeightError={sd.error.emptyWeightError}
                    loadedWeightError={sd.error.loadedWeightError}
                    cageBirdCountError={sd.error.cageBirdCountError}
                    onSeqNoChange={handleSeqNo}
                    onNumCagesChange={handleNumCages}
                    onEmptyWeightChange={handleEmptyWeight}
                    onLoadedWeightChange={handleLoadedWeight}
                    onCageBirdCountChange={handleCageBirdCount}
                    key={sd.key}
                    id={sd.key}
                />
            })}
            {isLoading
                ? <button className="btn btn-lg btn-block btn-secondary" style={{ cursor: 'no-drop' }} onClick={e => e.preventDefault()}>{type === "ADD" ? "ADD" : "UPDATE"}</button>
                : <button className="btn btn-lg btn-block btn-primary" onClick={handleSales}>{type === "ADD" ? "ADD" : "UPDATE"}</button>}
        </div >
    </div >
}
export default SalesForm
