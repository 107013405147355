import React, { Fragment, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import { isEmptyObj, today } from '../include/function'
import { pdf } from '../include/pdf'
import Error from '../shared/Error'
import Option from '../shared/Option'

const Report = () => {
    const [vendorId, setVendorId] = useState('')
    const [date, setDate] = useState(today())
    const [shedId, setShedId] = useState('')
    const [vendorList, setVendorList] = useState([])
    const [shedList, setShedList] = useState([])
    const [reportList, setReportList] = useState([])
    const [loading, setLoading] = useState(true)
    const [totalBirdCount, setTotalBirdCount] = useState(0)
    const [totalBirdWeight, setTotalBirdWeight] = useState(0)
    const [averageWeight, setAverageWeight] = useState(0)


    /*Total Bird Count	:
Total Bird Weight	:
Average Weight	:


    */



    const handleDate = (e) => {
        setDate(e.target.value)
    }
    const handleVendorId = (e) => {
        setShedId('')
        setVendorId(e.target.value)
    }
    const handleShedId = (e) => {
        setShedId(e.target.value)
    }
    const getReport = () => {
        setLoading(true)
        api.get(`report/?date=${date}&vendor_id=${vendorId}&shed_id=${shedId}`, getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { data } = response.data
                setReportList(data)
            }
        }).catch(error => {
            setReportList([])
        }).finally(() => {
            setLoading(false)
        })
    }

    const getVendorList = () => {
        api.get('vendor/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorList(data)
            }
        }).catch(error => {
            setVendorList([])
        })
    }
    const getShedList = () => {
        api.get(`shed/?vendor_id=${vendorId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setShedList(data)

            }
        }).catch(error => {
            setShedList([])
        })
    }
    useEffect(() => {
        getVendorList()

    }, [])
    useEffect(() => {
        if (vendorId !== '' && shedId !== "" && date !== "") {
            getReport()
        }

    }, [date, vendorId, shedId])

    useEffect(() => {
        if (vendorId !== '') {
            getShedList()
        }
    }, [vendorId]) // eslint-disable-line

    useEffect(() => {
        if (reportList.length > 0) {
            const { birdCount, birdWeight } = reportList.reduce((prev, current) => {
                return {
                    birdCount: prev.birdCount + current.cageBirdCount,
                    birdWeight: prev.birdWeight + (current.loadedWeight - current.emptyWeight)
                }
            }, { birdCount: 0, birdWeight: 0 })

            setTotalBirdCount(birdCount)
            setTotalBirdWeight(birdWeight)
            setAverageWeight(birdWeight / birdCount)
        }
    }, [reportList])

    return <Fragment>
        <div className="osahan-forgot">
            <div className="bg-light border-bottom p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="text-primary" to="/">
                        <i className="feather-chevron-left"></i>
                        <span>Back</span>
                    </Link>
                </div>
            </div>
            <div className="p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className='text-center'>Delivery Report</h2>

                    <button className='btn btn-primary' onClick={pdf}>Download</button>
                </div>
                <hr />
                <form className="mt-5 mb-4">
                    <div className="form-group me-4">
                        <label htmlFor="date">Date</label>
                        <input type="date" className="form-control input-sm me-4" id="date" onChange={handleDate} value={date} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="vendor">Vendor</label>
                        <select id="vendor" className="form-control" onChange={handleVendorId} value={vendorId}>
                            <option value="">Select Vendor</option>
                            {vendorList.map((vendor, index) => {
                                return <Option
                                    value={vendor.id}
                                    text={vendor.name}
                                    key={index}
                                />
                            })}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="shed">Shed</label>
                        <select id="shed" className="form-control" onChange={handleShedId} value={shedId}>
                            <option value="">Select Shed</option>
                            {shedList.map((shed, index) => {
                                return <Option
                                    value={shed.id}
                                    text={shed.name}
                                    key={index}
                                />
                            })}
                        </select>
                    </div>
                </form>
                <table id="detailstable">
                    <tbody>
                        <tr>
                            <th style={{ width: '100px' }}>Date</th>
                            <td>:&nbsp;{reportList[0] && reportList[0].createdDate}</td>
                            <td style={{ width: "10px" }}></td>
                            <th>Vendor</th>
                            <td>:&nbsp;{reportList[0] && reportList[0].vendor.name}</td>
                        </tr>
                        <tr>

                        </tr>
                        <tr>
                            <th>Location</th>
                            <td>:&nbsp;{reportList[0] && reportList[0].location.name}</td>
                            <td style={{ width: "10px" }}></td>
                            <th>Shed</th>
                            <td>:&nbsp;{reportList[0] && reportList[0].shed.name}</td>
                        </tr>
                        <tr>
                            <th>Customer</th>
                            <td>:&nbsp;{reportList[0] && reportList[0].buyer.userName}</td>
                            <td style={{ width: "10px" }}></td>
                            <th>Vehicle Number</th>
                            <td>:&nbsp;{reportList[0] && reportList[0].vehicleNumber}</td>
                        </tr>
                        <tr>
                            <th>Driver Name</th>
                            <td>: {reportList[0] && reportList[0].driverName}</td>
                        </tr>
                    </tbody>
                </table>
                <hr />
                <div className="overflow-auto mt-4">
                    < table className="w-100 table table-striped table-hover table-bordered" id='pdftable'>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Cage Count</th>
                                <th>Empty Weight</th>
                                <th>Loaded Weight</th>
                                <th>Bird Weight</th>
                                <th>Bird Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportList.map((report, index) => {
                                return <ReportBody
                                    sNo={index + 1}
                                    id={report.id}
                                    cageCount={report.numCages}
                                    emptyWeight={report.emptyWeight}
                                    loadedWeight={report.loadedWeight}
                                    birdWeight={report.loadedWeight - report.emptyWeight}
                                    cageBirdCount={report.cageBirdCount}
                                    key={index}
                                />
                            })}
                        </tbody>
                    </table>
                    <div className='align-items-center'>
                        <table id="totaltable">
                            <tbody>
                                <tr>
                                    <th>Total Bird Count</th>
                                    <td><h6>: {totalBirdCount}</h6> </td>
                                </tr>
                                <tr>
                                    <th>Total Bird Weight</th>
                                    <td><h6>: {Number(totalBirdWeight).toFixed(2)} kg</h6></td>
                                </tr>
                                <tr>
                                    <th>Average Weight</th>
                                    <td><h6>: {Number(averageWeight).toFixed(2)} kg</h6></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr />
            </div>
        </div >


    </Fragment >

}

const ReportBody = ({ sNo, cageCount, emptyWeight, loadedWeight, birdWeight, cageBirdCount }) => {
    return <tr>
        <td className="align-middle text-start" >{sNo}</td>
        <td className="align-middle text-start">{cageCount}</td>
        <td className="align-middle text-start text-capitalize">{emptyWeight}</td>
        <td className="align-middle text-start text-capitalize">{loadedWeight}</td>
        <td className="align-middle text-start text-capitalize">{birdWeight}</td>
        <td className="align-middle text-start text-capitalize">{cageBirdCount}</td>
    </tr>
}
export default Report
// {!isEmptyObj(livestock) && livestock.title}