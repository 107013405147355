import React, { useState, useContext } from "react";
import { Link } from 'react-router-dom'
import ToastContext from '../context/ToastContext'
import { api, getAxiosConfig } from '../include/api'
const ChangePassword = ({ userId, role }) => {
    const { toast } = useContext(ToastContext)
    const [oldPassword, setOldPassword] = useState('')
    const [oldPasswordError, setOldPasswordError] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newPasswordError, setNewPasswordError] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('')
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showRetypePassword, setShowRetypePassword] = useState(false)
    const toggleShowOldPassword = (e) => setShowOldPassword(!showOldPassword)
    const toggleShowNewPassword = (e) => setShowNewPassword(!showNewPassword)
    const toggleShowRetypePassword = (e) => setShowRetypePassword(!showRetypePassword)
    const handleOldPassword = (e) => {
        setOldPasswordError('')
        setOldPassword(e.target.value)
    }
    const handleNewPassword = (e) => {
        setNewPasswordError('')
        setNewPassword(e.target.value)
    }
    const handleConfirmNewPassword = (e) => {
        setConfirmNewPasswordError('')
        setConfirmNewPassword(e.target.value)
    }
    const handleChangePassword = (e) => {
        e.preventDefault()
        let error = false
        const data = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword
        }
        if (data.oldPassword === '') {
            setOldPasswordError("Old Password required")
            error = true
        }

        if (data.newPassword === '') {
            setNewPasswordError("New Password required")
            error = true
        } else if (data.newPassword.length < 6 || data.newPassword.length > 8) {
            setNewPasswordError("Password must be 6 to 8 characters long")
            error = true
        }
        if (data.confirmNewPassword !== data.newPassword) {
            setConfirmNewPasswordError("Password doesn't match")
            error = true
        }
        if (error === false) {
            api.put(`${role === 'vendor' ? 'vendor' : 'user'}/?user_id=${userId}`, data, getAxiosConfig()).then((response) => {
                if (response.status === 200) {
                    toast(response.data.message);
                }
            }).catch(error => {
                error.response
                    ? toast(error.response.data.message)
                    : toast(error.message)
            })
        }
    }
    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/profile"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>Change Password</h2>
            <form className="mt-5 mb-4">
                <div className="form-group">
                    <div className="input-group">
                        <input type={showOldPassword ? "text" : "password"} className="form-control" placeholder="Old password" onChange={handleOldPassword} />
                        <span className="input-group-text small btn" onClick={toggleShowOldPassword}>
                            <i className={`feather-${showOldPassword ? "eye" : "eye-off"}`} ></i>
                        </span>
                    </div>
                    <span className="d-inline-block text-danger">{oldPasswordError}</span>
                </div>
                <div className="form-group">
                    <div className="input-group">
                        <input type={showNewPassword ? "text" : "password"} className="form-control" placeholder="New password" onChange={handleNewPassword} />
                        <span className="input-group-text small btn" onClick={toggleShowNewPassword}>
                            <i className={`feather-${showNewPassword ? "eye" : "eye-off"}`}></i>
                        </span>
                    </div>
                    <span className="d-inline-block text-danger">{newPasswordError}</span>
                </div>
                <div className="form-group mb-3">
                    <div className="input-group">
                        <input type={showRetypePassword ? "text" : "password"} className="form-control" placeholder="Re-type password" onChange={handleConfirmNewPassword} />
                        <span className="input-group-text small btn" onClick={toggleShowRetypePassword} >
                            <i className={`feather-${showRetypePassword ? "eye" : "eye-off"}`}></i>
                        </span>
                    </div>
                    <span className="d-inline-block text-danger">{confirmNewPasswordError}</span>
                </div>
                <button className="btn btn-primary btn-lg btn-block text-uppercase" onClick={handleChangePassword}>Update</button>
            </form>
        </div >
    </div >
}
export default ChangePassword