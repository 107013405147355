import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ToastContext from '../context/ToastContext'
import { api, getAxiosConfig } from '../include/api'
import Option from '../shared/Option'

const MortalityForm = ({ type }) => {
    const { toast } = useContext(ToastContext)
    const params = useParams()

    const [details, setDetails] = useState('')
    const [detailsError, setDetailsError] = useState('')
    const [dayCount, setDayCount] = useState('')
    const [dayCountError, setDayCountError] = useState('')
    const [mortalityCount, setMortalityCount] = useState('')
    const [mortalityCountError, setMortalityCountError] = useState('')
    const [batchId, setBatchId] = useState('')
    const [locationId, setLocationId] = useState('')
    const [locationIdError, setLocationIdError] = useState('')
    const [shedId, setShedId] = useState('')
    const [shedIdError, setShedIdError] = useState('')
    const [batchIdError, setBatchIdError] = useState('')
    const [batchList, setBatchList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [shedList, setShedList] = useState([])
    const handleDetails = (e) => {
        setDetailsError('')
        setDetails(e.target.value)
    }
    const handleDayCount = (e) => {
        setDayCountError('')
        setDayCount(e.target.value)
    }
    const handleMortalityCount = (e) => {
        setMortalityCountError('')
        setMortalityCount(e.target.value)
    }
    const handleBatchId = (e) => {
        setBatchIdError('')
        setBatchId(e.target.value)
    }
    const handleShedId = (e) => {
        setBatchId('')
        setBatchList([])
        setShedIdError('')
        setShedId(e.target.value)
    }
    const handleLocationId = (e) => {
        setShedId('')
        setBatchId('')
        setBatchList([])
        setShedList([])
        setLocationIdError('')
        setLocationId(e.target.value)
    }

    const handleSubmitMortality = (e) => {
        e.preventDefault()

        let error = false

        const data = {
            details: details,
            dayCount: dayCount,
            mortalityCount: mortalityCount,
            batchId: batchId,
            locationId: locationId,
            shedId: shedId
        }

        if (data.details === '') {
            setDetailsError("Details required")
            error = true
        } else if (data.details > 100) {
            setDetailsError("Details must be within 100 characters")
            error = true
        }

        if (data.dayCount === '') {
            setDayCountError("Day Count required")
            error = true
        } else if (data.dayCount <= 0) {
            setDayCountError("Day Count must be a valid number")
            error = true
        }



        if (data.mortalityCount === '') {
            setMortalityCountError("Mortality Count required")
            error = true
        } else if (data.mortalityCount <= 0) {
            setMortalityCountError("Mortality Count must be a valid number")
            error = true
        }

        if (data.batchId === '') {
            setBatchIdError("Batch required")
            error = true
        }

        if (data.locationId === '') {
            setLocationIdError("Location required")
            error = true
        }
        if (data.shedId === '') {
            setShedIdError("Shed required")
            error = true
        }


        if (error === false) {
            if (type === "ADD") {
                api.post('mortality/', data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                        setDetails('')
                        setDayCount('')
                        setMortalityCount('')
                        setBatchId('')
                        setLocationId('')
                        setShedId('')
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                })
            } else if (type === "EDIT") {
                api.put(`mortality/?id=${params.id}`, data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                })
            }
        }
    }

    const getMortality = () => {
        api.get(`mortality/?id=${params.id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setDetails(data.details)
                setDayCount(data.dayCount)
                setMortalityCount(data.mortalityCount)
                setBatchId(data.batch.id)
                setShedId(data.shed.id)
                setLocationId(data.location.id)

            }
        })
    }
    const getBatchList = () => {
        api.get(`batch/?shed_id=${shedId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setBatchList(data)
            }
        }).catch(error => {
            setBatchList([])
        })
    }
    const getLocationList = () => {
        api.get(`location/`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setLocationList(data)

            }
        }).catch(error => {
            setLocationList([])
        })
    }

    const getShedList = () => {
        api.get(`shed/?location_id=${locationId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setShedList(data)

            }
        }).catch(error => {
            setShedList([])
        })
    }
    useEffect(() => {
        if (type === "EDIT") {
            getMortality()
        }
    }, []) // eslint-disable-line 

    useEffect(() => {
        if (shedId !== "") {
            getBatchList()
        }
    }, [shedId])// eslint-disable-line 
    useEffect(() => {
        if (locationId !== "") {
            getShedList()
        }
    }, [locationId])// eslint-disable-line
    useEffect(() => {
        getLocationList()
    }, [])// eslint-disable-line
    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/mortality"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>{type === "ADD" ? "Add" : "Edit"} Mortality</h2>
            <form className="mt-5 mb-4">

                <div className="form-group">
                    <label htmlFor="details">Details</label>
                    <input type="textbox" className="form-control" id="details" onChange={handleDetails} value={details} />
                    <span className="d-inline-block text-danger">{detailsError}</span>
                </div>

                <div className="form-group">
                    <label htmlFor="dayCount">Day Count</label>
                    <input type="number" className="form-control" id="dayCount" onChange={handleDayCount} value={dayCount} />
                    <span className="d-inline-block text-danger">{dayCountError}</span>
                </div>

                <div className="form-group">
                    <label htmlFor="mortalityCount">Mortality Count</label>
                    <input type="number" className="form-control" id="mortalityCount" onChange={handleMortalityCount} value={mortalityCount} />
                    <span className="d-inline-block text-danger">{mortalityCountError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <select id="location" className="form-control" onChange={handleLocationId} value={locationId}>
                        <option value="">All</option>
                        {locationList.map((location, index) => {
                            return <Option
                                value={location.id}
                                text={location.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {locationIdError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="shed">Shed</label>
                    <select id="shed" className="form-control" onChange={handleShedId} value={shedId}>
                        <option value="">All</option>
                        {shedList.map((shed, index) => {
                            return <Option
                                value={shed.id}
                                text={shed.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {shedIdError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="batch">Batch</label>
                    <select id="batch" className="form-control" onChange={handleBatchId} value={batchId}>
                        <option value="">Select Batch</option>
                        {batchList.map((batch, index) => {
                            return <Option
                                value={batch.id}
                                text={batch.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {batchIdError}</span>
                </div>
                <button className="btn btn-primary btn-lg btn-block text-uppercase" onClick={handleSubmitMortality}>{type === "ADD" ? "Add" : "update"}</button>
            </form>
        </div>
    </div>
}

export default MortalityForm
