import React, { useContext, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import ToastContext from '../context/ToastContext'
import { api, getAxiosConfig } from '../include/api'
import Option from '../shared/Option'

const LocationForm = ({ type }) => {
    const { toast } = useContext(ToastContext)
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()

    const [vendorId, setVendorId] = useState('')
    const [vendorIdError, setVendorIdError] = useState('')
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [description, setDescription] = useState('')
    const [descriptionError, setDescriptionError] = useState('')

    const [vendorList, setVendorList] = useState([])


    const handleVendorId = (e) => {
        setVendorIdError('')
        setVendorId(e.target.value)
    }

    const handleName = (e) => {
        setNameError('')
        setName(e.target.value)
    }

    const handleDescription = (e) => {
        setDescriptionError('')
        setDescription(e.target.value)
    }

    const handleLocation = (e) => {
        e.preventDefault()

        let error = false

        const data = {
            name: name,
            description: description,
            vendorId: vendorId,
        }

        if (data.name === "") {
            setNameError("Name required")
            error = true
        } else if (data.name.length > 30) {
            setNameError("Name must be within 30 characters")
            error = true
        }

        if (data.description === "") {
            setDescriptionError("Description required")
            error = true
        } else if (data.description.length > 100) {
            setDescriptionError("Description must be within 100 characters")
            error = true
        }

        if (data.vendorId === "") {
            setVendorIdError("Vendor required")
            error = true
        } else if (data.vendorId <= 0) {
            setVendorIdError("Vendor must be a valid number")
            error = true
        }

        if (error === false) {
            setIsLoading(true)
            if (type === "ADD") {
                api.post('location/', data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                        setName('')
                        setDescription('')
                        setVendorId('')

                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                }).finally(() => {
                    setIsLoading(false)
                })
            } else if (type === "EDIT") {
                setIsLoading(true)
                api.put(`location/?id=${params.id}`, data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)

                }).finally(() => {
                    setIsLoading(false)
                })

            }
        }
    }
    const getLocation = () => {
        api.get(`location/?id=${params.id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setName(data.name)
                setDescription(data.description)
                setVendorId(data.vendor.id)
            }
        })
    }

    const getVendorList = () => {
        api.get('vendor/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorList(data)
            }
        }).catch(error => {
            setVendorList([])

        })
    }

    useEffect(() => {
        if (type === "EDIT") {
            getLocation()
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        getVendorList()
    }, [])

    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/location"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>{type === "ADD" ? "Add" : "Edit"} Location</h2>
            <form className="mt-5 mb-4">

                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" className="form-control" onChange={handleName} value={name} />
                    <span className="d-inline-block text-danger"> {nameError}</span>

                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea id="description" className="form-control" onChange={handleDescription} value={description} style={{ resize: "none" }} />
                    <span className="d-inline-block text-danger">{descriptionError}</span>

                </div>

                <div className="form-group">
                    <label htmlFor="vendor">Vendor</label>
                    <select id="vendor" className="form-control" onChange={handleVendorId} value={vendorId}>
                        <option value="">Select Vendor</option>
                        {vendorList.map((vendor, index) => {
                            return <Option
                                value={vendor.id}
                                text={vendor.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {vendorIdError}</span>
                </div>


                {isLoading
                    ? <button className="btn btn-lg btn-block btn-secondary" style={{ cursor: 'no-drop' }} onClick={e => e.preventDefault()}>{type === "ADD" ? "ADD" : "UPDATE"}</button>
                    : <button className="btn btn-lg btn-block btn-primary" onClick={handleLocation}>{type === "ADD" ? "ADD" : "UPDATE"}</button>}
            </form>
        </div >
    </div >
}
export default LocationForm
