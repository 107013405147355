import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Spinner from '../shared/Spinner'
import Modal from '../shared/Modal'
import Option from '../shared/Option'
import Error from '../shared/Error'
import ToastContext from '../context/ToastContext'
import { addKey, today } from '../include/function'


const Batch = ({ user }) => {
    const { toast } = useContext(ToastContext)
    const [batchList, setBatchList] = useState([])
    const [shedList, setShedList] = useState([])
    const [vendorList, setVendorList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [loading, setLoading] = useState(true)

    const [showFilter, setShowFilter] = useState(false)

    const [fromDate, setFromDate] = useState(today())
    const [toDate, setToDate] = useState(today())
    const [shedId, setShedId] = useState('')
    const [vendorId, setVendorId] = useState(user.role === 'vendor' ? user.id : '')
    const [locationId, setLocationId] = useState('')

    const handleFilter = () => setShowFilter(true)

    const handleFromDate = (e) => {
        setFromDate(e.target.value)
    }

    const handleToDate = (e) => setToDate(e.target.value)

    const handleShedId = (e) => {
        setShedId(e.target.value)
    }
    const handleVendorId = (e) => {
        setLocationId('')
        setShedId('')
        setLocationList([])
        setShedList([])
        setVendorId(e.target.value)
    }
    const handleLocationId = (e) => {
        setShedId('')
        setShedList([])
        setLocationId(e.target.value)
    }

    const getBatchList = () => {
        setLoading(true)
        api.get(`batch/?from_date=${fromDate}&to_date=${toDate}&shed_id=${shedId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setBatchList(addKey(data))
                console.log(batchList);
            }
        }).catch(error => {
            setBatchList([])
        }).finally(() => {
            setLoading(false)
        })
    }


    const getShedList = () => {
        api.get(`shed/?location_id=${locationId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setShedList(addKey(data))
            }
        }).catch(error => {
            setShedList([])
        })
    }
    const getVendorList = () => {
        api.get('vendor/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorList(addKey(data))
            }
        }).catch(error => {
            if (user.role === "admin") {
                setVendorList([])
            }
        })
    }
    const getLocationList = () => {
        api.get(`location/?vendor_id=${vendorId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setLocationList(addKey(data))
            }
        }).catch(error => {
            setLocationList([])
        })
    }


    const handleDelete = (id) => {
        api.delete(`batch/?id=${id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { message } = response.data
                toast(message)
                setBatchList(prev => {
                    return prev.filter(batch => {
                        return batch.id !== id
                    })
                })
            }
        }).catch(error => {

        })
    }

    useEffect(() => {
        getBatchList()
    }, [fromDate, toDate, shedId]) // eslint-disable-line

    useEffect(() => {
        if (user.role !== "vendor") {
            getVendorList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (vendorId !== "") getLocationList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorId])

    useEffect(() => {
        if (locationId !== "") getShedList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId])

    return <Fragment>
        {showFilter && <Modal setShowModal={setShowFilter}>
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" id="fromDate" onChange={handleFromDate} value={fromDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" id="toDate" onChange={handleToDate} value={toDate} />
                </div>
                {user.role === "admin" && <div className="form-group" >
                    <label htmlFor="vendor">Vendor</label>
                    <select id="vendor" className="form-control" onChange={handleVendorId} value={vendorId}>
                        <option value="">All</option>
                        {vendorList.map((vendor, index) => {
                            return <Option
                                value={vendor.id}
                                text={vendor.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>}

                <div className="form-group" >
                    <label htmlFor="location">Location</label>
                    <select id="location" className="form-control" onChange={handleLocationId} value={locationId}>
                        <option value="">All</option>
                        {locationList.map((location, index) => {
                            return <Option
                                value={location.id}
                                text={location.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="shedId">Shed</label>
                    <select id="shedId" className="form-control" onChange={handleShedId} value={shedId}>
                        <option value="">All</option>
                        {shedList.map(shed => {
                            return <Option
                                value={shed.id}
                                text={shed.name}
                                key={shed.key}
                            />
                        })}
                    </select>
                </div>
            </div>
        </Modal>
        }
        <div className="osahan-forgot">
            <div className="bg-light border-bottom p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="text-primary" to="/">
                        <i className="feather-chevron-left"></i>
                        <span>Back</span>
                    </Link>
                    {user.role === "admin" && <Link to="add" className="text-primary">
                        <span>Add</span>
                        <i className="feather-chevron-right" />
                    </Link>}
                </div>
            </div>
            <div className="p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className="m-0">Batch</h2>
                    <button className="btn btn-primary" onClick={handleFilter}>
                        <i className="feather-filter mr-1" />
                        <span>Filter</span>
                    </button>
                </div>
                <div className="overflow-auto mt-4">
                    {loading
                        ? <Spinner />
                        : batchList.length > 0
                            ? <table className="w-100 table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Description </th>
                                        <th>Shed</th>
                                        <th className='text-truncate'>Total Count</th>
                                        {user.role === "admin" && <th className="text-center" colSpan={2}>Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {batchList.map((batch, index) => {

                                        return <BatchBody

                                            id={batch.id}
                                            sNo={index + 1}
                                            date={batch.createdDate}
                                            name={batch.name}
                                            description={batch.description}
                                            shed={batch.shed && batch.shed.name}
                                            totalCount={batch.totalCount}
                                            key={index}
                                            onDelete={() => handleDelete(batch.id)}
                                            user={user}
                                        />

                                    })}
                                </tbody>
                            </table>
                            : <Error errorText="NO RECORDS FOUND" />}
                </div>
            </div>
        </div>
    </Fragment >

}

const BatchBody = ({ id, sNo, date, name, description, totalCount, shed, onDelete, user }) => {
    return <tr>
        <td className="align-middle">{sNo}</td>
        <td className="align-middle text-truncate">{date}</td>
        <td className="align-middle text-capitalize">{name}</td>
        <td className="align-middle text-capitalize">{description}</td>
        <td className="align-middle text-capitalize">{shed}</td>
        <td className="align-middle">{totalCount}</td>
        {user.role === "admin" && <td className="align-middle text-center">
            <Link to={`edit/${id}`} className="btn btn-primary btn-sm mx-1">Edit</Link>
        </td>}
        {user.role === "admin" && <td className="align-middle text-center">
            <button className="btn btn-primary btn-sm mx-1" onClick={onDelete}>Delete</button>
        </td>}
    </tr>
}

export default Batch