import React, { useContext, Fragment, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ToastContext from '../context/ToastContext'
import { api, getAxiosConfig } from '../include/api'
import { isValidEmail, isValidPhoneNumber, isValidUsername } from '../include/function'

const VendorForm = ({ type }) => {
    const { toast } = useContext(ToastContext)
    const params = useParams()

    const [loginId, setLoginId] = useState('')
    const [loginIdError, setLoginIdError] = useState('')
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [address1, setAddress1] = useState('')
    const [address1Error, setAddress1Error] = useState('')
    const [address2, setAddress2] = useState('')
    const [address2Error, setAddress2Error] = useState('')
    const [address3, setAddress3] = useState('')
    const [address3Error, setAddress3Error] = useState('')
    const [contactNumber, setContactNumber] = useState('')
    const [contactNumberError, setContactNumberError] = useState('')
    const [emailId, setEmailId] = useState('')
    const [emailIdError, setEmailIdError] = useState('')
    const [status, setStatus] = useState('')
    const [statusError, setStatusError] = useState('')

    const handleLoginId = (e) => {
        setLoginIdError('')
        setLoginId(String(e.target.value).toLowerCase())
    }
    const handlePassword = (e) => {
        setPasswordError('')
        setPassword(e.target.value)
    }
    const handleName = (e) => {
        setNameError('')
        setName(e.target.value)
    }
    const handleAddress1 = (e) => {
        setAddress1Error('')
        setAddress1(e.target.value)

    }
    const handleAddress2 = (e) => {
        setAddress2Error('')
        setAddress2(e.target.value)

    }
    const handleAddress3 = (e) => {
        setAddress3Error('')
        setAddress3(e.target.value)

    }
    const handleEmailId = (e) => {
        setEmailIdError('')
        setEmailId(e.target.value)
    }

    const handleStatus = (e) => {
        setStatusError('')
        setStatus(e.target.value)
    }
    const handleContactNumber = (e) => {
        setContactNumberError('')
        setContactNumber(e.target.value)
    }
    const handleSubmitVendor = (e) => {
        e.preventDefault()

        let error = false

        const data = type === "ADD" ? {
            loginId: String(loginId).toLowerCase(),
            password: password,
            name: name,
            address1: address1,
            address2: address2,
            address3: address3,
            contactNumber: contactNumber,
            emailId: emailId,

        } : {
            name: name,
            address1: address1,
            address2: address2,
            address3: address3,
            contactNumber: contactNumber,
            emailId: emailId,
            status: status
        }

        if (type === "ADD") {
            if (data.loginId === '') {
                setLoginIdError("Username required")
                error = true
            } else if (!isValidUsername(data.loginId)) {
                setLoginIdError("Sorry, only small letters (a-z), numbers (0-9), and underscore (_) are allowed.")
                error = true
            } else if (data.loginId.length < 4 || data.loginId.length > 30) {
                setLoginIdError("Username must be 4 to 30 characters long")
                error = true
            }

            if (data.password === '') {
                setPasswordError("Password required")
                error = true
            } else if (data.password.length < 6 || data.password.length > 8) {
                setPasswordError("Password must be 6 to 8 characters long")
                error = true
            }
        }

        if (data.name === '') {
            setNameError("Name required")
            error = true
        } else if (data.name.length > 30) {
            setNameError("Name must be within 30 characters long")
            error = true
        }

        if (data.emailId !== '' && !isValidEmail(data.emailId)) {
            setEmailIdError("Email must be a valid one")
            error = true
        }

        if (data.contactNumber === "") {
            setContactNumberError("Contact number required")
            error = true
        } else if (!isValidPhoneNumber(data.contactNumber)) {
            setContactNumberError("Contact number must be a valid one")
            error = true
        }

        if (data.address1 === "") {
            setAddress1Error("Address line 1 required")
            error = true
        } else if (data.address1 > 50) {
            setAddress1Error("Address line 1 must be within 50 characters")
            error = true
        }

        if (data.address2 === "") {
            setAddress2Error("Address line 2 required")
            error = true
        } else if (data.address2 > 50) {
            setAddress2Error("Address line 2 must be within 50 characters")
            error = true
        }

        if (data.address3 === "") {
            setAddress3Error("Address line 3 required")
            error = true
        } else if (data.address3 > 50) {
            setAddress3Error("Address line 3 must be within 50 characters")
            error = true
        }

        if (type === "EDIT") {
            if (data.status === '') {
                setStatusError("Status required")
                error = true
            } else if (["active", "inactive"].indexOf(data.status) === -1) {
                setStatusError("Status must be active or inactive only")
                error = true
            }
        }

        if (error === false) {
            if (type === "ADD") {
                api.post('vendor/', data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message)
                        setLoginId('')
                        setPassword('')
                        setName('')
                        setAddress1('')
                        setAddress2('')
                        setAddress3('')
                        setEmailId('')
                        setContactNumber('')
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                })
            } else if (type === "EDIT") {
                api.put(`vendor/?id=${params.id}`, data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                })
            }
        }
    }

    const getUser = () => {
        api.get(`vendor/?id=${params.id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setName(data.name)
                setAddress1(data.address1)
                setAddress2(data.address2)
                setAddress3(data.address3)
                setEmailId(data.emailId)
                setContactNumber(data.contactNumber)
                setStatus(data.status)
            }
        })
    }

    useEffect(() => {
        if (type === "EDIT") {
            getUser()
        }
    }, []) // eslint-disable-line

    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/vendor"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>{type === "ADD" ? "Add" : "Edit"} Vendor</h2>
            <form className="mt-5 mb-4">

                {type === "ADD" && <Fragment>
                    <div className="form-group">
                        <label htmlFor="LoginId">Username</label>
                        <input type="text" className="form-control" id="loginId" onChange={handleLoginId} value={loginId} />
                        <span className="d-inline-block text-danger">{loginIdError}</span>
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" id="password" onChange={handlePassword} value={password} />
                        <span className="d-inline-block text-danger">{passwordError}</span>
                    </div>
                </Fragment>}

                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" onChange={handleName} value={name} />
                    <span className="d-inline-block text-danger">{nameError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="address1">Address line 1</label>
                    <textarea className="form-control" id="address1" onChange={handleAddress1} value={address1} style={{ resize: "none" }} />
                    <span className="d-inline-block text-danger">{address1Error}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="address2">Address line 2</label>
                    <textarea className="form-control" id="address2" onChange={handleAddress2} value={address2} style={{ resize: "none" }} />
                    <span className="d-inline-block text-danger">{address2Error}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="address3">Address line 3</label>
                    <textarea className="form-control" id="address3" onChange={handleAddress3} value={address3} style={{ resize: "none" }} />
                    <span className="d-inline-block text-danger">{address3Error}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="emailId">Email</label>
                    <input type="email" className="form-control" id="emailId" onChange={handleEmailId} value={emailId} />
                    <span className="d-inline-block text-danger">{emailIdError}</span>
                </div>

                <div className="form-group">
                    <label htmlFor="contactNumber">Contact number</label>
                    <input type="number" className="form-control" id="contactNumber" onChange={handleContactNumber} value={contactNumber} />
                    <span className="d-inline-block text-danger">{contactNumberError}</span>
                </div>

                {type === "EDIT" && <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <select id="status" className="form-control" onChange={handleStatus} value={status}>
                        <option value="">Select Status </option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                    <span className="d-inline-block text-danger">{statusError}</span>
                </div>}

                <button className="btn btn-primary btn-lg btn-block text-uppercase" onClick={handleSubmitVendor}>{type === "ADD" ? "Add" : "update"}</button>
            </form>
        </div>
    </div>
}

export default VendorForm
