import React, { useContext, useState, useEffect, Fragment } from 'react'
import { Link, useParams } from 'react-router-dom'
import ToastContext from '../context/ToastContext'
import { api, getAxiosConfig } from '../include/api'
import Option from '../shared/Option'

const ShedForm = ({ type }) => {
    const { toast } = useContext(ToastContext)
    const params = useParams()

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [description, setDescription] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [locationId, setLocationId] = useState('')
    const [locationIdError, setLocationIdError] = useState('')
    const [vendorId, setVendorId] = useState('')
    const [vendorIdError, setVendorIdError] = useState('')

    const [locationList, setLocationList] = useState([])
    const [vendorList, setVendorList] = useState([])

    const handleName = (e) => {
        setNameError('')
        setName(e.target.value)
    }

    const handleDescription = (e) => {
        setDescriptionError('')
        setDescription(e.target.value)
    }

    const handleLocationId = (e) => {
        setLocationIdError('')
        setLocationId(e.target.value)
    }

    const handleVendorId = (e) => {
        setVendorIdError('')
        setLocationId('')
        setVendorId(e.target.value)
    }
    const handleSubmitShed = (e) => {
        e.preventDefault()

        let error = false

        const data = {
            name: name,
            description: description,
            locationId: locationId,
            vendorId: vendorId

        }

        if (data.name === '') {
            setNameError("Name required")
            error = true
        } else if (data.name.length > 30) {
            setNameError("Name must be within 30 characters only")
            error = true
        }

        if (data.description === '') {
            setDescriptionError("Description required")
            error = true
        } else if (data.description.length > 100) {
            setDescriptionError("Description must be within 100 characters only")
            error = true
        }
        if (data.vendorId === '') {
            setVendorIdError("Vendor required")
            error = true
        }
        if (data.locationId === '') {
            setLocationIdError("Location required")
            error = true
        }


        if (error === false) {
            if (type === "ADD") {
                api.post('shed/', data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                        setName('')
                        setDescription('')
                        setLocationId('')
                        setVendorId('')
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                })
            } else if (type === "EDIT") {
                api.put(`shed/?id=${params.id}`, data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                })

            }
        }
    }

    const getLocationList = () => {
        api.get(`location/?vendor_id=${vendorId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setLocationList(data)
            }
        }).catch(error => {
            setLocationList([])

        })
    }
    const getVendorList = () => {
        api.get('vendor/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorList(data)
            }
        }).catch(error => {
            setVendorList([])

        })
    }
    useEffect(() => {
        getVendorList()
    }, []) // eslint-disable-line
    useEffect(() => {
        getLocationList()
    }, [vendorId]) // eslint-disable-line
    const getShed = () => {
        api.get(`shed/?id=${params.id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setName(data.name)
                setDescription(data.description)
                setLocationId(data.location.id)
                setVendorId(data.vendor.id)

            }
        })
    }

    useEffect(() => {
        if (type === "EDIT") {
            getShed()
        }
    }, []) // eslint-disable-line


    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/shed"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>{type === "ADD" ? "Add" : "Edit"} Shed</h2>
            <form className="mt-5 mb-4">
                {(type === "ADD" || type === "EDIT") && <Fragment>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input type="text" className="form-control" id="name" onChange={handleName} value={name} />
                        <span className="d-inline-block text-danger">{nameError}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea className="form-control" id="description" onChange={handleDescription} value={description} style={{ resize: "none" }} />
                        <span className="d-inline-block text-danger">{descriptionError}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="vendorId">Vendor</label>
                        <select id="vendorId" className="form-control" onChange={handleVendorId} value={vendorId}>
                            <option value="">Select Vendor</option>
                            {vendorList.map((vendor, index) => {
                                return <Option
                                    value={vendor.id}
                                    text={vendor.name}
                                    key={index}
                                />
                            })}
                        </select>
                        <span className="d-inline-block text-danger">{vendorIdError}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="location">Location</label>
                        <select id="location" className="form-control" onChange={handleLocationId} value={locationId}>
                            <option value="">Select Location</option>
                            {locationList.map((location, index) => {
                                return <Option
                                    value={location.id}
                                    text={location.name}
                                    key={index}
                                />
                            })}
                        </select>
                        <span className="d-inline-block text-danger">{locationIdError}</span>
                    </div>


                </Fragment>}


                <button className="btn btn-primary btn-lg btn-block text-uppercase" onClick={handleSubmitShed}>{type === "ADD" ? "add" : "update"}</button>
            </form>
        </div >
    </div >
}

export default ShedForm
