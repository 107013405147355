import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Spinner from '../shared/Spinner'
import Error from '../shared/Error'
import Modal from '../shared/Modal'
import ToastContext from '../context/ToastContext'

const User = ({ user }) => {
    const { toast } = useContext(ToastContext)
    const [showPasswordModal, setShowPasswordModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [resetedPassword, setResetedPassword] = useState('')

    const [userList, setUserList] = useState([])

    const handleShowPasswordModal = (password) => {
        setShowPasswordModal(true)
        setResetedPassword(password)
    }
    const handleDelete = (userId) => {
        api.delete(`user/?user_id=${userId}`, getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { message } = response.data
                toast(message)
                setUserList(prev => {
                    return prev.filter(user => {
                        return user.userId !== userId
                    })
                })
            }

        })

    }


    const getUserList = () => {
        setLoading(true)
        api.get('user/', getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { data } = response.data
                setUserList(data)
            }
        }).catch(error => {

        }).finally(() => {
            setLoading(false)
        })
    }



    useEffect(() => {
        getUserList()
    }, [])

    return <>
        {showPasswordModal && <Modal setShowModal={setShowPasswordModal}>
            <div className="d-block p-2 bg-light text-secondary text-center" > Your new Password is
                <h3 className=" fs-3 d-block p-2  text-success  text-center"> {resetedPassword} </h3>
            </div>

        </Modal>}
        <div className="osahan-forgot">
            <div className="bg-light border-bottom p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="text-primary" to="/">
                        <i className="feather-chevron-left"></i>
                        <span>Back</span>
                    </Link>
                    {user.role === "admin" && <Link to="add" className="text-primary">
                        <span>Add</span>
                        <i className="feather-chevron-right" />
                    </Link>}
                </div>
            </div>
            <div className="p-4">
                <h2 className="m-0">User</h2>
                <div className="overflow-auto mt-4">
                    {loading
                        ? <Spinner />
                        : userList.length > 0
                            ? <table className="w-100 table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Username</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Role</th>
                                        {user.role === "admin" && <th className='text-center' colSpan={2}>Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {userList.map((u, index) => {
                                        return <UserBody
                                            sNo={index + 1}
                                            date={u.createdDate}
                                            userId={u.userId}
                                            email={u.emailId}
                                            username={u.name}
                                            status={u.status}
                                            role={u.role}
                                            handleShowPasswordModal={handleShowPasswordModal}
                                            onDelete={() => handleDelete(u.userId)}
                                            key={index}
                                            user={user}
                                        />
                                    })}
                                </tbody>
                            </table>
                            : <Error errorText="NO USER RECORDS FOUND" />}
                </div>
            </div>
        </div >
    </>
}
const UserBody = ({ sNo, date, userId, email, username, status, role, handleShowPasswordModal, onDelete, user }) => {
    const { toast } = useContext(ToastContext)

    const handleResetPassword = () => {

        api.put(`user/?user_id=${userId}`, {}, getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { data, message } = response.data
                toast(message)
                handleShowPasswordModal(data.password)
            }
        }).catch(error => {

        })
    }

    return <tr>
        <td className="align-middle" >{sNo}</td>
        <td className="align-middle text-truncate" >{date}</td>
        <td className="align-middle text-capitalize">{username}</td>
        <td className="align-middle">{email}</td>
        <td className="align-middle  ">
            <div className={`text-capitalize btn btn-${status === 'active' ? 'success' : 'danger'}`}>{status}</div>
        </td>
        <td className="align-middle  text-capitalize">{role}</td>

        {user.role === "admin" && <td className="align-middle">
            <div className="d-flex align-items-start">
                <Link to={`edit/${userId}`} className="btn btn-primary btn-sm mx-1">Edit</Link>
                <button className="btn btn-primary btn-sm mx-1" onClick={handleResetPassword}>Reset&nbsp;Password</button>

            </div>
        </td>}
        {user.role === "admin" && <td className="align-middle">
            <div className="d-flex align-items-start">
                <button className="btn btn-primary btn-sm mx-1" onClick={onDelete}>Delete</button>

            </div>
        </td>}
    </tr>
}

export default User