import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Spinner from '../shared/Spinner'
import Error from '../shared/Error'
import Modal from '../shared/Modal'
import { today } from '../include/function'
import ToastContext from '../context/ToastContext'

const Vendor = ({ user }) => {
    const { toast } = useContext(ToastContext)
    const [showPasswordModal, setShowPasswordModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [resetedPassword, setResetedPassword] = useState('')
    const [showFilter, setShowFilter] = useState('')

    const [fromDate, setFromDate] = useState(today())
    const [toDate, setToDate] = useState(today())
    const [status, setStatus] = useState('')


    const [vendorList, setVendorList] = useState([])
    const handleFilter = () => setShowFilter(true)

    const handleFromDate = (e) => {
        setFromDate(e.target.value)
    }
    const handleToDate = (e) => setToDate(e.target.value)
    const handleStatus = (e) => {
        setStatus(e.target.value)
    }

    const getVendorList = () => {
        setLoading(true)
        api.get(`vendor/?from_date=${fromDate}&to_date=${toDate}&status=${status}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorList(data)
            }
        }).catch(error => {
            setVendorList([])
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getVendorList()
    }, [fromDate, toDate, status]) // eslint-disable-line

    const handleShowPasswordModal = (password) => {
        setShowPasswordModal(true)
        setResetedPassword(password)
    }
    const handleDelete = (id) => {
        api.delete(`vendor/?id=${id}`, getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { message } = response.data
                toast(message)
                setVendorList(prev => {
                    return prev.filter(vendor => {
                        return vendor.id !== id
                    })
                })
            }

        })

    }




    return <>
        {showFilter && <Modal setShowModal={setShowFilter}>
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" id="fromDate" onChange={handleFromDate} value={fromDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" id="toDate" onChange={handleToDate} value={toDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <select id="status" className="form-control" onChange={handleStatus} value={status}>
                        <option value="">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                </div>
            </div>
        </Modal>}
        {showPasswordModal && <Modal setShowModal={setShowPasswordModal}>
            <div className="d-block p-2 bg-light text-secondary text-center" > Your new Password is
                <h3 className=" fs-3 d-block p-2  text-success  text-center"> {resetedPassword} </h3>
            </div>

        </Modal>}
        <div className="osahan-forgot">
            <div className="bg-light border-bottom p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="text-primary" to="/">
                        <i className="feather-chevron-left"></i>
                        <span>Back</span>
                    </Link>
                    {user.role === "admin" && <Link to="add" className="text-primary">
                        <span>Add</span>
                        <i className="feather-chevron-right" />
                    </Link>}
                </div>
            </div>
            <div className="p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className="m-0">Vendor</h2>
                    <button className="btn btn-primary" onClick={handleFilter}>
                        <i className="feather-filter mr-1" />
                        <span>Filter</span>
                    </button>
                </div>
                <div className="overflow-auto mt-4">
                    {loading
                        ? <Spinner />
                        : vendorList.length > 0
                            ? <table className="w-100 table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Address</th>
                                        <th>Contact</th>
                                        <th>Status</th>
                                        {user.role === "admin" && <th className="text-center" colSpan={3}>Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {vendorList.map((vendor, index) => {
                                        return <VendorBody
                                            id={vendor.id}
                                            sNo={index + 1}
                                            date={vendor.createdDate}
                                            username={vendor.name}
                                            address={`${vendor.address1}, ${vendor.address2} ,${vendor.address3}`}
                                            contactNumber={vendor.contactNumber}
                                            emailId={vendor.emailId}
                                            status={vendor.status}
                                            handleShowPasswordModal={handleShowPasswordModal}
                                            onDelete={() => handleDelete(vendor.id)}
                                            key={index}
                                            user={user}
                                        />
                                    })}
                                </tbody>
                            </table>
                            : <Error errorText="NO Vendor RECORDS FOUND" />}
                </div>
            </div>
        </div >
    </>
}
const VendorBody = ({ id, sNo, date, username, address, status, contactNumber, handleShowPasswordModal, onDelete, emailId, user }) => {
    const { toast } = useContext(ToastContext)

    const handleResetPassword = () => {

        api.put(`vendor/?id=${id}`, {}, getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { data, message } = response.data
                toast(message)
                handleShowPasswordModal(data.password)
            }
        }).catch(error => {

        })
    }

    return <tr>
        <td className="align-middle" >{sNo}</td>
        <td className="align-middle text-truncate" >{date}</td>
        <td className="align-middle text-capitalize">{username}</td>
        <td className="align-middle text-capitalize">{address} </td>
        <td className="align-middle text-capitalize">
            {contactNumber}
            <br />
            {emailId}
        </td>
        <td className="align-middle">
            <div className={`text-capitalize btn btn-${status === 'active' ? 'success' : 'danger'} `}>{status}</div>
        </td>

        {user.role === "admin" && <td className="align-middle">
            <div className="d-flex align-items-start">
                <Link to={`edit/ ${id}`} className="btn btn-primary btn-sm mx-1">Edit</Link>
                <button className="btn btn-primary btn-sm mx-1" onClick={handleResetPassword}>Reset&nbsp;Password</button>
                <button className="btn btn-primary btn-sm mx-1" onClick={onDelete}>Delete</button>

            </div>
        </td>}

    </tr>
}

export default Vendor
