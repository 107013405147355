import React from 'react'

const SalesDetails = ({ seqNo, numCages, emptyWeight, loadedWeight, cageBirdCount, seqNoError, numCagesError, emptyWeightError, loadedWeightError, cageBirdCountError, id, onNumCagesChange, onEmptyWeightChange, onLoadedWeightChange, onCageBirdCountChange, onSeqNoChange }) => {

    const handleSeqno = (e) => {
        onSeqNoChange(id, e.target.value)
    }
    const handleNumCages = (e) => {
        onNumCagesChange(id, e.target.value)
    }
    const handleEmptyWeight = (e) => {
        onEmptyWeightChange(id, e.target.value)
    }
    const handleLoadedWeight = (e) => {
        onLoadedWeightChange(id, e.target.value)
    }
    const handleCageBirdCount = (e) => {
        onCageBirdCountChange(id, e.target.value)
    }

    return <>
        <div className="border border-dark p-3 mb-3">
            <form className="mt-5 mb-4">
                <div className="form-group">
                    <label htmlFor="seqNo">Seq No</label>
                    <input type="number" id="seqNo" className="form-control" onChange={handleSeqno} value={seqNo} />
                    <span className="d-inline-block text-danger">{seqNoError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="totalCount">No.of Cages</label>
                    <input type="number" id="totalCount" className="form-control" onChange={handleNumCages} value={numCages} />
                    <span className="d-inline-block text-danger">{numCagesError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="emptyWeight">Empty Weight</label>
                    <input type="number" id="emptyWeight" className="form-control" onChange={handleEmptyWeight} value={emptyWeight} />
                    <span className="d-inline-block text-danger">{emptyWeightError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="loadedWeight">Loaded Weight</label>
                    <input type="number" id="loadedWeight" className="form-control" onChange={handleLoadedWeight} value={loadedWeight} />
                    <span className="d-inline-block text-danger">{loadedWeightError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="cageBirdCount">Cage Bird Count</label>
                    <input type="number" id="cageBirdCount" className="form-control" onChange={handleCageBirdCount} value={cageBirdCount} />
                    <span className="d-inline-block text-danger">{cageBirdCountError}</span>
                </div>
            </form >
        </div>
    </>
}
export default SalesDetails