import React, { useState, useContext } from 'react'
import { api } from '../include/api'
import ToastContext from '../context/ToastContext'
import { setLoggedInUser } from '../include/function'

const Login = ({ setUser }) => {
    const { toast } = useContext(ToastContext)

    const [userId, setUserId] = useState('')
    const [userIdError, setUserIdError] = useState('')
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [isLoggingIn, setIsLoggingIn] = useState(false)

    const handleUserId = (value) => {
        setUserIdError('')
        setUserId(String(value).trim())
    }

    const handlePassword = (value) => {
        setPasswordError('')
        setPassword(value)
    }

    const handleSignIn = (e) => {
        e.preventDefault()

        let error = false

        const data = {
            userId: userId.trim(),
            password: password
        }

        if (data.userId === '') {
            setUserIdError('Username required')
            error = true
        }

        if (data.password === '') {
            setPasswordError('Password required')
            error = true
        }

        if (!error) {
            setIsLoggingIn(true)
            api.post('user/login/', data).then(response => {
                if (response.status === 200) {
                    const { user } = response.data.data
                    setLoggedInUser(user)
                    setTimeout(() => {
                        setUser(user)
                    }, 500)
                }
            }).catch(error => {
                error.response
                    ? toast(error.response.data.message)
                    : toast(error.message)
            }).finally(() => {
                setIsLoggingIn(false)
            })
        }
    }


    return <div className="login-page vh-100">
        <div className="p-4 bg-primary">
            <h2 className="my-0 text-white">Welcome Back</h2>
            <p className="text-50 text-white m-0">Sign in to continue</p>
        </div>
        <div className="p-4">
            <form className="mt-5 mb-4">
                <div className="form-group">
                    <label htmlFor="username" className="">Username</label>
                    <input type="text" placeholder="Enter Username" className="mb-1 form-control text-dark border-bottom" id="username" value={userId} onChange={e => handleUserId(e.target.value)} />
                    <span className="d-inline-block text-danger">{userIdError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="password" className="">Password</label>
                    <input type="password" placeholder="Enter Password" className="mb-1 form-control text-dark border-bottom" id="password" value={password} onChange={e => handlePassword(e.target.value)} />
                    <span className="d-inline-block text-danger">{passwordError}</span>
                </div>
                {isLoggingIn
                    ? <button className="btn btn-lg btn-block btn-secondary" style={{ cursor: 'no-drop' }} onClick={e => e.preventDefault()}>SIGN IN</button>
                    : <button className="btn btn-lg btn-block btn-primary" onClick={handleSignIn}>SIGN IN</button>}
            </form>
        </div>
    </div>
}

export default Login