import React from 'react'
import { Link } from 'react-router-dom'

const Home = ({ user }) => {
    return <div className="osahan-home-page fixed-top-bar">
        <div className="bg-primary p-3 fixed-top">
            <div className="text-white">
                <div className="title d-flex align-items-center">
                    <h4 className="font-weight-bold m-0">Poultry</h4>
                </div>
            </div>
        </div>
        <div className="bg-light p-3">
            <div className="bg-white rounded shadow profile-details">
                {(user.role === "admin" || user.role === "supervisor" || user.role === "marketing") && <Link to="/sales" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-dollar-sign bg-danger text-white p-2 rounded-circle mr-2" />
                            <span>Sales</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}
                {(user.role === "admin" || user.role === "supervisor" || user.role === "vendor") && <Link to="/expense" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-dollar-sign bg-danger text-white p-2 rounded-circle mr-2" />
                            <span>Expense</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}
                {user.role === "admin" && <Link to="/vendor" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-users bg-primary text-white p-2 rounded-circle mr-2" />
                            <span>Vendor</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}
                {(user.role === "admin" || user.role === "vendor") && <Link to="/location" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-map-pin bg-success text-white p-2 rounded-circle mr-2" />
                            <span>Location</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}
                {(user.role === "admin" || user.role === "vendor") && <Link to="/shed" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-home bg-success text-white p-2 rounded-circle mr-2" />
                            <span>Shed</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}
                {(user.role === "admin" || user.role === "vendor") && <Link to="/batch" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-grid bg-success text-white p-2 rounded-circle mr-2" />
                            <span>Batch</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}
                {(user.role === "admin" || user.role === "supervisor" || user.role === "vendor") && <Link to="/feed" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-grid bg-primary text-white p-2 rounded-circle mr-2" />
                            <span>Feed</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}
                {(user.role === "admin" || user.role === "supervisor" || user.role === "vendor") && <Link to="/feed_stock_history" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className=" feather-file-text bg-primary text-white p-2 rounded-circle mr-2" />
                            <span>Feed Stock History</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}
                {user.role === "admin" && <Link to="/buyer" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-user bg-success text-white p-2 rounded-circle mr-2" />
                            <span>Buyer</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}
                {(user.role === "admin" || user.role === "marketing") && <Link to="/buyer_order" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-truck bg-success text-white p-2 rounded-circle mr-2" />
                            <span>Buyer Order</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}
                {(user.role === "admin" || user.role === "supervisor" || user.role === "vendor") && <Link to="/growth" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-droplet bg-success text-white p-2 rounded-circle mr-2" />
                            <span>Growth</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}
                {(user.role === "admin" || user.role === "supervisor" || user.role === "vendor") && <Link to="/mortality" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-droplet bg-danger text-white p-2 rounded-circle mr-2" />
                            <span>Mortality</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}
                {user.role === 'admin' && <Link to="/user" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-users bg-primary text-white p-2 rounded-circle mr-2" />
                            <span>User</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}
                <Link to="/profile" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-user bg-primary text-white p-2 rounded-circle mr-2" />
                            <span>Profile</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>
                <Link to="/logout" className="d-flex w-100 align-items-center px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-log-out bg-success text-white p-2 rounded-circle mr-2" />
                            <span>Logout</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>
                {user.role === 'admin' && <Link to="/report" className="d-flex w-100 align-items-center border-bottom px-3 py-4">
                    <div className="left mr-3">
                        <h6 className="font-weight-bold m-0 text-dark">
                            <i className="feather-users bg-primary text-white p-2 rounded-circle mr-2" />
                            <span>Report</span>
                        </h6>
                    </div>
                    <div className="right ml-auto">
                        <h6 className="font-weight-bold m-0"><i className="feather-chevron-right" /></h6>
                    </div>
                </Link>}

            </div>
        </div>
    </div>
}

export default Home