
import { nanoid } from "nanoid"
export const getLoggedInUser = () => {
    const loggedUser = localStorage.getItem('loggedUser')
    return loggedUser ? JSON.parse(loggedUser) : {}
}
export const getToken = () => {
    const loggedUser = localStorage.getItem('loggedUser')
    return loggedUser ? JSON.parse(loggedUser).token : ''
}

export const setLoggedInUser = (loggedUser) => {
    localStorage.setItem('loggedUser', JSON.stringify(loggedUser))

}

export const removeLoggedInUser = () => {
    localStorage.removeItem('loggedUser')
}

export const isEmptyObj = (variable) => {
    if (variable && typeof variable === 'object' && Object.entries(variable).length === 0) return true
    return false
}
export const isValidEmail = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm.test(String(email).toLowerCase())
}

export const isValidUsername = (username) => {
    return /^[a-z0-9_]+$/gm.test(String(username).toLowerCase())
}

export const date = () => {
    return new Date().getDate()
}

export const month = () => {
    return new Date().getMonth() + 1
}

export const year = () => {
    return new Date().getFullYear()
}

export const today = () => {
    return [year(), String(month()).padStart(2, '0'), String(date()).padStart(2, '0')].join('-')
}

export const isValidPhoneNumber = (contactNumber) => {
    return (/^[6-9]{1}[0-9]{9}$/gm).test(contactNumber);
}

export const addKey = (arrayOfObject) => {
    return arrayOfObject.reduce((previous, current) => {
        return [
            ...previous,
            {
                ...current,
                key: nanoid()
            }
        ]
    }, [])
}

export const formateDate = (dateString) => {
    const date = new Date(dateString)
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const yyyy = date.getFullYear()
    return [yyyy, mm, dd].join('-')
}