import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Error from '../shared/Error'
import ToastContext from '../context/ToastContext'
import Option from '../shared/Option'
import Modal from '../shared/Modal'
import Spinner from '../shared/Spinner'
import { today } from '../include/function'

const Location = ({ user }) => {
    const { toast } = useContext(ToastContext)
    const [locationList, setLocationList] = useState([])
    const [vendorList, setVendorList] = useState([])
    const [loading, setLoading] = useState(true)

    const [showFilter, setShowFilter] = useState(false)

    const [fromDate, setFromDate] = useState(today())
    const [toDate, setToDate] = useState(today())
    const [vendorId, setVendorId] = useState(user.role === 'vendor' ? user.id : '')

    const handleFilter = () => setShowFilter(true)

    const handleFromDate = (e) => {
        setFromDate(e.target.value)
    }

    const handleToDate = (e) => setToDate(e.target.value)

    const handleVendorId = (e) => {
        setVendorId(e.target.value)
    }

    const getLocationList = () => {
        setLoading(true)
        api.get(`location/${user.role === 'vendor' ? `?vendor_id=${user.id}` : `?from_date=${fromDate}&to_date=${toDate}&vendor_id=${vendorId}`}`, getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { data } = response.data
                setLocationList(data)
            }
        }).catch(error => {
            setLocationList([])
        }).finally(() => {
            setLoading(false)
        })
    }

    const getVendorList = () => {
        api.get('vendor/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorList(data)
            }
        }).catch(error => {
            setVendorList([])
        })
    }

    const handleDelete = (id) => {
        api.delete(`location/?id=${id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { message } = response.data
                toast(message)
                setLocationList(prev => {
                    return prev.filter(location => {
                        return location.id !== id
                    })
                })
            }
        }).catch(error => {

        })
    }

    useEffect(() => {
        if (user.role === "admin") {
            getVendorList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getLocationList()
    }, [fromDate, toDate, vendorId]) // eslint-disable-line

    return <Fragment>
        {showFilter && <Modal setShowModal={setShowFilter}>
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" id="fromDate" onChange={handleFromDate} value={fromDate} />
                </div>

                <div className="form-group">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" id="toDate" onChange={handleToDate} value={toDate} />
                </div>

                {user.role === "admin" && <div className="form-group">
                    <label htmlFor="vendor">Vendor</label>
                    <select id="vendor" className="form-control" onChange={handleVendorId} value={vendorId}>
                        <option value="">All</option>
                        {vendorList.map((vendor, index) => {
                            return <Option
                                value={vendor.id}
                                text={vendor.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>}
            </div>
        </Modal>}
        <div className="osahan-forgot">
            <div className="bg-light border-bottom p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="text-primary" to="/">
                        <i className="feather-chevron-left"></i>
                        <span>Back</span>
                    </Link>
                    {user.role === "admin" && <Link to="add" className="text-primary">
                        <span>Add</span>
                        <i className="feather-chevron-right" />
                    </Link>}
                </div>
            </div>
            <div className="p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h2>Location</h2>
                    {user.role === "admin" && <button className="btn btn-primary" onClick={handleFilter}>
                        <i className="feather-filter mr-1" />
                        <span>Filter</span>
                    </button>}
                </div>
                <div className="overflow-auto mt-4">
                    {loading
                        ? <Spinner />
                        : locationList.length > 0
                            ? <table className="w-100 table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Vendor</th>
                                        {user.role === "admin" && <th className="text-center" colSpan={2}>Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {locationList.map((location, index) => {
                                        return <LocationBody
                                            sNo={index + 1}
                                            id={location.id}
                                            date={location.createdDate}
                                            location={location.name}
                                            description={location.description}
                                            vendor={location.vendor.name}
                                            key={index}
                                            onDelete={() => handleDelete(location.id)}
                                            user={user}

                                        />
                                    })}
                                </tbody>
                            </table>
                            : <Error errorText="NO LOCATION RECORDS FOUND" />}
                </div>
            </div>
        </div >
    </Fragment>
}

const LocationBody = ({ sNo, id, date, location, description, vendor, onDelete, user }) => {
    return <tr>
        <td className="align-middle text-start" >{sNo}</td>
        <td className="align-middle text-start text-truncate">{date}</td>
        <td className="align-middle text-start text-capitalize">{location}</td>
        <td className="align-middle text-start text-capitalize">{description}</td>
        <td className="align-middle text-start text-capitalize">{vendor}</td>
        {user.role === "admin" && <td className="align-middle text-center">
            <Link to={`edit/${id}`} className="btn btn-primary btn-sm mx-1">Edit</Link>
        </td>}
        {user.role === "admin" && <td className="align-middle text-center">
            <button className="btn btn-primary btn-sm mx-1" onClick={onDelete}>Delete</button>
        </td>}
    </tr>

}

export default Location