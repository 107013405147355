import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Option from '../shared/Option'
import ToastContext from '../context/ToastContext'
import { formateDate } from '../include/function'

const BuyerOrderForm = ({ type }) => {
    const params = useParams()
    const { toast } = useContext(ToastContext)
    const [isLoading, setIsLoading] = useState(false)

    const [buyerId, setBuyerId] = useState('')
    const [buyerIdError, setBuyerIdError] = useState('')

    const [count, setCount] = useState('')
    const [countError, setCountError] = useState('')


    const [avgWeight, setAvgWeight] = useState('')
    const [avgWeightError, setAvgWeightError] = useState('')
    const [deliveryDate, setDeliveryDate] = useState('')
    const [deliveryDateError, setDeliveryDateError] = useState('')


    const [buyerList, setBuyerList] = useState([])


    const handleBuyerId = (e) => {
        setBuyerIdError('')
        setBuyerId(e.target.value)
    }

    const handleCount = (e) => {
        setCountError('')
        setCount(e.target.value)

    }
    const handleAvgWeight = (e) => {
        setAvgWeightError('')
        setAvgWeight(e.target.value)

    }
    const handleDeliveryDate = (e) => {
        setDeliveryDateError('')
        setDeliveryDate(e.target.value)

    }


    const handleBuyerOrder = (e) => {
        e.preventDefault()
        let error = false
        const data = {
            buyerId: buyerId,
            count: count,
            avgWeight: avgWeight,
            deliveryDate: deliveryDate

        }
        if (type === "ADD") {
            if (data.buyerId === "") {
                setBuyerIdError("Batch required")
                error = true
            }
        }
        if (data.count === "") {
            setCountError("Count required")
            error = true
        } else if (data.count <= 0) {
            setCountError("Count must be a valid number")
            error = true
        }
        if (data.avgWeight === "") {
            setAvgWeightError("Average Weight  required")
            error = true
        } else if (data.avgWeight <= 0) {
            setAvgWeightError("Average weight must be a valid number")
            error = true
        }
        if (type === "ADD") {
            if (data.deliveryDate === "") {
                setDeliveryDateError("Delivery Date required")
                error = true
            }
        }

        if (error === false) {
            if (type === "ADD") {
                setIsLoading(true)
                api.post('buyer_order/', data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                        setBuyerId('')
                        setCount('')
                        setAvgWeight('')
                        setDeliveryDate('')
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                }).finally(() => {
                    setIsLoading(false)
                })
            } else if (type === "EDIT") {
                setIsLoading(true)
                api.put(`buyer_order/?id=${params.id}`, data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                }).finally(() => {
                    setIsLoading(false)
                })
            }

        }

    }
    const getBuyerList = () => {
        api.get('buyer/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setBuyerList(data)
            }
        }).catch(error => {

        })
    }
    const getBuyerOrder = () => {
        api.get(`buyer_order/?id=${params.id}`, getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { data } = response.data
                setBuyerId(data.buyer.id)
                setCount(data.count)
                setAvgWeight(data.avgWeight)
                setDeliveryDate(formateDate(data.deliveryDate))

            }
        })
    }
    useEffect(() => {
        if (type === "EDIT") {
            getBuyerOrder()
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        getBuyerList()
    }, [])


    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/buyer_order"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>{type === "ADD" ? "Add" : "Edit"} Buyer Order</h2>
            <form className="mt-5 mb-4">
                {type === "ADD" &&
                    <div className="form-group">
                        <label htmlFor="buyer">Buyer</label>
                        <select id="buyer" className="form-control" onChange={handleBuyerId} value={buyerId}>
                            <option value="">Select Buyer</option>
                            {buyerList.map((buyer, index) => {
                                return <Option
                                    value={buyer.id}
                                    text={buyer.name}
                                    key={index}
                                />
                            })}
                        </select>
                        <span className="d-inline-block text-danger"> {buyerIdError}</span>

                    </div>}


                <div className="form-group">
                    <label htmlFor="count">Count</label>
                    <input type="number" className="form-control" id="count" onChange={handleCount} value={count} />
                    <span className="d-inline-block text-danger">{countError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="avgWeight">Average Weight</label>
                    <input type="number" className="form-control" id="avgWeight" onChange={handleAvgWeight} value={avgWeight} />
                    <span className="d-inline-block text-danger">{avgWeightError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="deliveryDate">Delivery Date </label>
                    <input type="date" className="form-control" id="deliveryDate" onChange={handleDeliveryDate} value={deliveryDate} />
                    <span className="d-inline-block text-danger">{deliveryDateError}</span>
                </div>

                {isLoading
                    ? <button className="btn btn-lg btn-block btn-secondary" style={{ cursor: 'no-drop' }} onClick={e => e.preventDefault()}>{type === "ADD" ? "ADD" : "UPDATE"}</button>
                    : <button className="btn btn-lg btn-block btn-primary" onClick={handleBuyerOrder}>{type === "ADD" ? "ADD" : "UPDATE"}</button>}
            </form>
        </div >
    </div >
}
export default BuyerOrderForm
