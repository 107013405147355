import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ToastContext from '../context/ToastContext'
import { api, getAxiosConfig } from '../include/api'
import { isValidEmail, isValidPhoneNumber } from '../include/function'

const BuyerForm = ({ type }) => {
    const { toast } = useContext(ToastContext)
    const params = useParams()
    const [loading, setLoading] = useState('')

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [description, setDescription] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [address1, setAddress1] = useState('')
    const [address1Error, setAddress1Error] = useState('')
    const [address2, setAddress2] = useState('')
    const [address2Error, setAddress2Error] = useState('')
    const [address3, setAddress3] = useState('')
    const [address3Error, setAddress3Error] = useState('')
    const [contactNumber, setContactNumber] = useState('')
    const [contactNumberError, setContactNumberError] = useState('')
    const [emailId, setEmailId] = useState('')
    const [emailIdError, setEmailIdError] = useState('')
    const [status, setStatus] = useState('')
    const [statusError, setStatusError] = useState('')

    const handleName = (e) => {
        setNameError('')
        setName(e.target.value)
    }
    const handleDescription = (e) => {
        setDescriptionError('')
        setDescription(e.target.value)
    }
    const handleAddress1 = (e) => {
        setAddress1Error('')
        setAddress1(e.target.value)
    }
    const handleAddress2 = (e) => {
        setAddress2Error('')
        setAddress2(e.target.value)
    }
    const handleAddress3 = (e) => {
        setAddress3Error('')
        setAddress3(e.target.value)
    }
    const handleContactNumber = (e) => {
        setContactNumberError('')
        setContactNumber(e.target.value)
    }
    const handleEmailId = (e) => {
        setEmailIdError('')
        setEmailId(e.target.value)
    }
    const handleStatus = (e) => {
        setStatusError('')
        setStatus(e.target.value)
    }


    const handleSubmitBuyer = (e) => {
        e.preventDefault()

        let error = false

        const data = {
            name: name,
            description: description,
            address1: address1,
            address2: address2,
            address3: address3,
            contactNumber: contactNumber,
            emailId: emailId,
            status: status
        }

        if (data.name === '') {
            setNameError("Name required")
            error = true
        } else if (data.name.length > 30) {
            setNameError("Name must be with in 30 characters")
            error = true
        }
        if (data.description === '') {
            setDescriptionError("Description required")
            error = true
        } else if (data.description.length > 100) {
            setDescriptionError("Description  must be with in 100 characters")
            error = true
        }

        if (data.address1 === '') {
            setAddress1Error("Address Line 1 required")
            error = true
        } else if (data.address1.length > 100) {
            setAddress1Error("Address Line 1  must be with in 50 characters")
            error = true
        }
        if (data.address2 === '') {
            setAddress2Error("Address Line 2 required")
            error = true
        } else if (data.address2.length > 100) {
            setAddress2Error("Address Line 2  must be with in 50 characters")
            error = true
        }
        if (data.address3 === '') {
            setAddress3Error("Address Line 3 required")
            error = true
        } else if (data.address3.length > 100) {
            setAddress3Error("Address Line 3  must be with in 50 characters")
            error = true
        }

        if (data.contactNumber === '') {
            setContactNumberError("Contact number required")
            error = true
        } else if (!isValidPhoneNumber(data.contactNumber)) {
            setContactNumberError("Contact number must be a Valid one")
            error = true
        }

        if (data.emailId !== '' && !isValidEmail(data.emailId)) {
            setEmailIdError("Email must be a valid one")
            error = true
        }

        if (type === "EDIT") {
            if (data.status === '') {
                setStatusError("Status required")
                error = true
            }
        }

        if (error === false) {
            if (type === "ADD") {
                setLoading(true)
                api.post('buyer/', data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                        setName('')
                        setDescription('')
                        setAddress1('')
                        setAddress2('')
                        setAddress3('')
                        setContactNumber('')
                        setEmailId('')
                        setStatus('')
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                }).finally(() => {
                    setLoading(false)

                })
            } else if (type === "EDIT") {
                setLoading(true)
                api.put(`buyer/?id=${params.id}`, data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                }).finally(() => {
                    setLoading(false)

                })
            }
        }

    }

    const getBuyer = () => {
        api.get(`buyer/?id=${params.id}`, getAxiosConfig()).then((response) => {
            if (response.status === 200) {
                const { data } = response.data
                setName(data.name)
                setDescription(data.description)
                setAddress1(data.address1)
                setAddress2(data.address2)
                setAddress3(data.address3)
                setContactNumber(data.contactNumber)
                setEmailId(data.emailId)
                setStatus(data.status)
            }
        })
    }
    useEffect(() => {
        if (type === "EDIT") {
            getBuyer()
        }
    }, []) // eslint-disable-line

    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/buyer"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>{type === "ADD" ? "Add" : "Edit"} Buyer</h2>
            <form className="mt-5 mb-4">

                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" onChange={handleName} value={name} />
                    <span className="d-inline-block text-danger">{nameError}</span>
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea className="form-control" id="description" onChange={handleDescription} value={description} style={{ resize: "none" }} />
                    <span className="d-inline-block text-danger">{descriptionError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="address1">Address Line 1</label>
                    <textarea className="form-control" id="address1" onChange={handleAddress1} value={address1} style={{ resize: "none" }} />
                    <span className="d-inline-block text-danger">{address1Error}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="address2">Address Line 2</label>
                    <textarea className="form-control" id="address2" onChange={handleAddress2} value={address2} style={{ resize: "none" }} />
                    <span className="d-inline-block text-danger">{address2Error}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="address3">Address Line 3</label>
                    <textarea className="form-control" id="address3" onChange={handleAddress3} value={address3} style={{ resize: "none" }} />
                    <span className="d-inline-block text-danger">{address3Error}</span>
                </div>

                <div className="form-group">
                    <label htmlFor="contactNumber">Contact Number</label>
                    <input type="number" className="form-control" id="contactNumber" onChange={handleContactNumber} value={contactNumber} />
                    <span className="d-inline-block text-danger">{contactNumberError}</span>
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" className="form-control" id="amount" onChange={handleEmailId} value={emailId} />
                    <span className="d-inline-block text-danger">{emailIdError}</span>
                </div>
                {type === "EDIT" && <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <select id="status" className="form-control" onChange={handleStatus} value={status}>
                        <option value="">Select Status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                    <span className="d-inline-block text-danger">{statusError}</span>
                </div>}
                {loading
                    ? <button className="btn btn-lg btn-block btn-secondary text-uppercase" style={{ cursor: 'no-drop' }} onClick={e => e.preventDefault()}>{type === "ADD" ? "Add" : "Update"}</button>
                    : <button className="btn btn-lg btn-block btn-primary text-uppercase" onClick={handleSubmitBuyer}>{type === "ADD" ? "Add" : "Update"}</button>}
            </form>
        </div>
    </div>
}

export default BuyerForm
