
import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Option from '../shared/Option'
import ToastContext from '../context/ToastContext'

const FeedForm = ({ type }) => {
    const { toast } = useContext(ToastContext)
    const params = useParams()
    const [isLoading, setIsLoading] = useState(false)

    const [feedName, setFeedName] = useState('')
    const [feedNameError, setFeedNameError] = useState('')

    const [quantity, setQuantity] = useState('')
    const [quantityError, setQuantityError] = useState('')

    const [dayCount, setDayCount] = useState('')
    const [dayCountError, setDayCountError] = useState('')
    const [vendorId, setVendorId] = useState('')
    const [vendorIdError, setVendorIdError] = useState('')
    const [locationId, setLocationId] = useState('')
    const [locationIdError, setLocationIdError] = useState('')
    const [shedId, setShedId] = useState('')
    const [shedIdError, setShedIdError] = useState('')
    const [batchId, setBatchId] = useState('')
    const [batchIdError, setBatchIdError] = useState('')

    const [batchList, setBatchList] = useState([])
    const [vendorList, setVendorList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [shedList, setShedList] = useState([])

    const handleFeedName = (e) => {
        setFeedNameError('')
        setFeedName(e.target.value)
    }

    const handleBatchId = (e) => {
        setBatchIdError('')
        setBatchId(e.target.value)
    }
    const handleLocationId = (e) => {
        setShedId('')
        setBatchId('')
        setLocationIdError('')
        setLocationId(e.target.value)
        setShedList([])
        setBatchList([])
    }
    const handleVendorId = (e) => {
        setLocationId('')
        setShedId('')
        setBatchId('')
        setVendorIdError('')
        setVendorId(e.target.value)
        setShedList([])
        setBatchList([])
        setLocationList([])
    }
    const handleShedId = (e) => {
        setBatchId('')
        setShedIdError('')
        setShedId(e.target.value)
        setBatchList([])
    }


    const handleQuantity = (e) => {
        setQuantityError('')
        setQuantity(e.target.value)
    }

    const handleDayCount = (e) => {
        setDayCountError('')
        setDayCount(e.target.value)
    }

    const handleFeed = (e) => {
        e.preventDefault()

        let error = false

        const data = {
            feedName: feedName,
            quantity: quantity,
            dayCount: dayCount,
            vendorId: vendorId,
            locationId: locationId,
            shedId: shedId,
            batchId: batchId
        }

        if (data.feedName === "") {
            setFeedNameError("Title required")
            error = true
        } else if (data.feedName.length > 30) {
            setFeedNameError("Feed name must be within 30 characters")
            error = true
        }

        if (data.batchId === "") {
            setBatchIdError("Batch required")
            error = true
        }
        if (data.vendorId === "") {
            setVendorIdError("Vendor required")
            error = true
        }
        if (data.locationId === "") {
            setLocationIdError("Location required")
            error = true
        }
        if (data.shedId === "") {
            setShedIdError("Shed required")
            error = true
        }
        if (data.quantity === "") {
            setQuantityError("Quantity required")
            error = true
        } else if (data.quantity <= 0) {
            setQuantityError("Quantity must be valid number")
            error = true
        }

        if (data.dayCount === "") {
            setDayCountError("Day Count required")
            error = true
        } else if (data.dayCount <= 0) {
            setDayCountError("Day Count must be valid number")
            error = true
        }

        if (error === false) {
            setIsLoading(true)
            if (type === "ADD") {
                api.post('feed/', data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                        setFeedName('')
                        setBatchId('')
                        setDayCount('')
                        setQuantity('')
                        setVendorId('')
                        setShedId('')
                        setLocationId('')

                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                }).finally(() => {
                    setIsLoading(false)
                })
            } else if (type === "EDIT") {
                setIsLoading(true)
                api.put(`feed/?id=${params.id}`, data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)

                }).finally(() => {
                    setIsLoading(false)
                })

            }
        }
    }
    const getFeed = () => {
        api.get(`feed/?id=${params.id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                console.log(data);
                setFeedName(data.feedName)
                setBatchId(data.batch.id)
                setVendorId(data.vendor.id)
                setShedId(data.shed.id)
                setLocationId(data.location.id)
                setQuantity(data.quantity)
                setDayCount(data.dayCount)
            }
        })
    }

    useEffect(() => {
        if (type === "EDIT") {
            getFeed()
        }
    }, []) // eslint-disable-line

    const getBatchList = () => {
        api.get(`batch/?shed_id=${shedId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setBatchList(data)
            }
        }).catch(error => {
            setBatchList([])

        })
    }
    const getVendorList = () => {
        api.get('vendor/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorList(data)
            }
        }).catch(error => {
            setVendorList([])

        })
    }
    const getLocationList = () => {
        api.get(`location/?vendor_id=${vendorId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setLocationList(data)
            }
        }).catch(error => {
            setLocationList([])

        })
    }
    const getShedList = () => {
        api.get(`shed/?location_id=${locationId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setShedList(data)
            }
        }).catch(error => {
            setShedList([])

        })
    }


    useEffect(() => {
        if (shedId !== "") {
            getBatchList()
        }
    }, [shedId]) // eslint-disable-line
    useEffect(() => {
        if (vendorId !== "") {
            getLocationList()
        }
    }, [vendorId]) // eslint-disable-line
    useEffect(() => {
        if (locationId !== "") {
            getShedList()
        }
    }, [locationId]) // eslint-disable-line
    useEffect(() => {
        getVendorList()
    }, []) // eslint-disable-line
    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/feed"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>{type === "ADD" ? "Add" : "Edit"} Feed</h2>
            <form className="mt-5 mb-4">
                <div className="form-group">
                    <label htmlFor="feedName">Feed Name</label>
                    <input type="text" id="feedName" className="form-control" onChange={handleFeedName} value={feedName} />
                    <span className="d-inline-block text-danger">{feedNameError}</span>

                </div>

                <div className="form-group">
                    <label htmlFor="vendor">Vendor</label>
                    <select id="vendor" className="form-control" onChange={handleVendorId} value={vendorId}>
                        <option value="">Select Vendor</option>
                        {vendorList.map((vendor, index) => {
                            return <Option
                                value={vendor.id}
                                text={vendor.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {vendorIdError}</span>

                </div>
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <select id="location" className="form-control" onChange={handleLocationId} value={locationId}>
                        <option value="">Select Location</option>
                        {locationList.map((location, index) => {
                            return <Option
                                value={location.id}
                                text={location.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {locationIdError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="shed">Shed</label>
                    <select id="shed" className="form-control" onChange={handleShedId} value={shedId}>
                        <option value="">Select Shed</option>
                        {shedList.map((shed, index) => {
                            return <Option
                                value={shed.id}
                                text={shed.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {shedIdError}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="batch">Batch</label>
                    <select id="batch" className="form-control" onChange={handleBatchId} value={batchId}>
                        <option value="">Select batch</option>
                        {batchList.map((batch, index) => {
                            return <Option
                                value={batch.id}
                                text={batch.name}
                                key={index}
                            />
                        })}
                    </select>
                    <span className="d-inline-block text-danger"> {batchIdError}</span>

                </div>
                <div className="form-group">
                    <label htmlFor="quantity">Quantity</label>
                    <input type="number" id="quantity" className="form-control" onChange={handleQuantity} value={quantity} />
                    <span className="d-inline-block text-danger">{quantityError}</span>

                </div>
                <div className="form-group">
                    <label htmlFor="dayCount">Day Count</label>
                    <input type="number" className="form-control" id="dayCount" onChange={handleDayCount} value={dayCount} />
                    <span className="d-inline-block text-danger">{dayCountError}</span>
                </div>
                {isLoading
                    ? <button className="btn btn-lg btn-block btn-secondary" style={{ cursor: 'no-drop' }} onClick={e => e.preventDefault()}>{type === "ADD" ? "ADD" : "UPDATE"}</button>
                    : <button className="btn btn-lg btn-block btn-primary" onClick={handleFeed}>{type === "ADD" ? "ADD" : "UPDATE"}</button>}
            </form>
        </div >
    </div >
}
export default FeedForm
