import React, { useEffect, useState, Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Spinner from '../shared/Spinner'
import Modal from '../shared/Modal'
import Option from '../shared/Option'
import Error from '../shared/Error'
import ToastContext from '../context/ToastContext'
import { addKey, today } from '../include/function'
const Growth = ({ user }) => {
    const { toast } = useContext(ToastContext)
    const [growthList, setGrowthList] = useState([])
    const [batchList, setBatchList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [vendorList, setVendorList] = useState([])
    const [shedList, setShedList] = useState([])
    const [createdByList, setCreatedByList] = useState([])
    const [loading, setLoading] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [fromDate, setFromDate] = useState(today())
    const [toDate, setToDate] = useState(today())
    const [batchId, setBatchId] = useState('')
    const [createdBy, setCreatedBy] = useState('')
    const [vendorId, setVendorId] = useState(user.role === 'vendor' ? user.id : '')
    const [shedId, setShedId] = useState('')
    const [locationId, setLocationId] = useState('')

    const handleFilter = () => setShowFilter(true)
    const handleFromDate = (e) => {
        setFromDate(e.target.value)
    }
    const handleToDate = (e) => setToDate(e.target.value)
    const handleBatchId = (e) => {
        setBatchId(e.target.value)
    }
    const handleVendorId = (e) => {
        setVendorId(e.target.value)
        setLocationId('')
        setShedId('')
        setBatchId('')
        setLocationList([])
        setShedList([])
        setBatchList([])
    }
    const handleLocationId = (e) => {
        setLocationId(e.target.value)
        setShedId('')
        setBatchId('')
        setShedList([])
        setBatchList([])
    }
    const handleShedId = (e) => {
        setShedId(e.target.value)
        setBatchId('')
        setBatchList([])
    }
    const handleCreatedBy = (e) => {
        setCreatedBy(e.target.value)
    }
    const handleDelete = (id) => {
        api.delete(`growth/?id=${id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { message } = response.data
                toast(message)
                setGrowthList(prev => {
                    return prev.filter(growth => {
                        return growth.id !== id
                    })
                })
            }
        }).catch(error => {
        })
    }
    const getGrowthList = () => {
        setLoading(true)
        api.get(`growth/?from_date=${fromDate}&to_date=${toDate}&batch_id=${batchId}&created_by=${createdBy}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setGrowthList(addKey(data))
            }
        }).catch(error => {
            setGrowthList([])
        }).finally(() => {
            setLoading(false)
        })
    }
    const getBatchList = () => {
        api.get(`batch/?shed_id=${shedId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setBatchList(addKey(data))
            }
        }).catch(error => {
            setBatchList([])
        })
    }
    const getVendorList = () => {
        api.get('vendor/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setVendorList(addKey(data))
            }
        }).catch(error => {
            setVendorList([])
        })
    }
    const getLocationList = () => {
        api.get(`location/?vendor_id=${vendorId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setLocationList(addKey(data))
            }
        }).catch(error => {
            setLocationList([])
        })
    }
    const getShedList = () => {
        api.get(`shed/?location_id=${locationId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setShedList(addKey(data))
            }
        }).catch(error => {
            setShedList([])
        })
    }
    const getUserList = () => {
        api.get('user/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setCreatedByList(addKey(data))
            }
        }).catch(error => {
            setCreatedByList([])
        })
    }
    useEffect(() => {
        getGrowthList()
    }, [fromDate, toDate, batchId, createdBy]) // eslint-disable-line
    useEffect(() => {
        if (user.role !== "vendor") {
            getUserList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (shedId !== "") {
            getBatchList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shedId])

    useEffect(() => {
        if (vendorId !== "") {
            getLocationList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorId])

    useEffect(() => {
        if (locationId !== "") {
            getShedList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationId])

    useEffect(() => {
        if (user.role !== "vendor") {
            getVendorList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Fragment>
        {showFilter && <Modal setShowModal={setShowFilter}>
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" id="fromDate" onChange={handleFromDate} value={fromDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" id="toDate" onChange={handleToDate} value={toDate} />
                </div>
                {(user.role === "admin" || user.role === "supervisor") && <div className="form-group">
                    <label htmlFor="vendor">Vendor</label>
                    <select id="vendor" className="form-control" onChange={handleVendorId} value={vendorId}>
                        <option value="">All</option>
                        {vendorList.map((vendor, index) => {
                            return <Option
                                value={vendor.id}
                                text={vendor.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>}
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <select id="location" className="form-control" onChange={handleLocationId} value={locationId}>
                        <option value="">All</option>
                        {locationList.map((location, index) => {
                            return <Option
                                value={location.id}
                                text={location.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="shed">Shed</label>
                    <select id="shed" className="form-control" onChange={handleShedId} value={shedId}>
                        <option value="">All</option>
                        {shedList.map((shed, index) => {
                            return <Option
                                value={shed.id}
                                text={shed.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="batch">Batch</label>
                    <select id="batch" className="form-control" onChange={handleBatchId} value={batchId}>
                        <option value="">All</option>
                        {batchList.map((batch, index) => {
                            return <Option
                                value={batch.id}
                                text={batch.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>
                {(user.role === "admin" || user.role === "supervisor") && <div className="form-group">
                    <label htmlFor="createdBy">Created By</label>
                    <select id="createdBy" className="form-control" onChange={handleCreatedBy} value={createdBy}>
                        <option value="">All</option>
                        {createdByList.map((createdBy, index) => {
                            return <Option
                                value={createdBy.userId}
                                text={createdBy.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>}
            </div>
        </Modal>}
        <div className="osahan-forgot">
            <div className="bg-light border-bottom p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="text-primary" to="/">
                        <i className="feather-chevron-left"></i>
                        <span>Back</span>
                    </Link>
                    {(user.role === "admin" || user.role === "supervisor") && <Link to="add" className="text-primary">
                        <span>Add</span>
                        <i className="feather-chevron-right" />
                    </Link>}
                </div>
            </div>
            <div className="p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h2 className="m-0">Growth</h2>
                    <button className="btn btn-primary" onClick={handleFilter}>
                        <i className="feather-filter mr-1" />
                        <span>Filter</span>
                    </button>
                </div>
                <div className="overflow-auto mt-4">
                    {loading
                        ? <Spinner />
                        : growthList.length > 0
                            ? <table className="w-100 table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th className='text-truncate'>Day Count</th>
                                        <th className='text-truncate'>Avg Weight</th>
                                        <th>Batch</th>
                                        <th className='text-truncate'>Created By</th>
                                        {(user.role === "admin" || user.role === "supervisor") && <th className='text-center' colSpan={2}>Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {growthList.map((growth, index) => {
                                        return <GrowthBody
                                            sNo={index + 1}
                                            id={growth.id}
                                            date={growth.createdDate}
                                            dayCount={growth.dayCount}
                                            avgWeight={growth.averageWeight}
                                            batch={growth.batch.name}
                                            createdBy={growth.created.userName}
                                            key={index}
                                            onDelete={() => handleDelete(growth.id)}
                                            user={user}
                                        />
                                    })}
                                </tbody>
                            </table>
                            : <Error errorText="NO RECORDS FOUND" />}
                </div>
            </div>
        </div>
    </Fragment>
}
const GrowthBody = ({ id, sNo, date, dayCount, avgWeight, batch, createdBy, onDelete, user }) => {
    return <tr>
        <td className="align-middle">{sNo}</td>
        <td className="align-middle text-capitalize text-truncate">{date}</td>
        <td className="align-middle ">{dayCount}</td>
        <td className="align-middle ">{avgWeight}kg</td>
        <td className="align-middle text-capitalize">{batch}</td>
        <td className="align-middle text-capitalize">{createdBy}</td>
        {(user.role === "admin" || user.role === "supervisor") && <td className="align-middle text-center">
            <Link to={`edit/${id}`} className="btn btn-primary btn-sm mx-1">Edit</Link>
        </td>}
        {(user.role === "admin" || user.role === "supervisor") && <td className="align-middle text-center">
            <button className="btn btn-primary btn-sm mx-1" onClick={onDelete}>Delete</button>
        </td>}
    </tr>
}
export default Growth


















