import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { api, getAxiosConfig } from '../include/api'
import Spinner from '../shared/Spinner'
import Error from '../shared/Error'
import Modal from '../shared/Modal'
import Option from '../shared/Option'
import ToastContext from '../context/ToastContext'
import { today } from '../include/function'

const Expense = ({ user }) => {
    const { toast } = useContext(ToastContext)
    const [loading, setLoading] = useState(true)

    const [expenseList, setExpenseList] = useState([])
    const [locationList, setLocationList] = useState([])
    const [shedList, setShedList] = useState([])
    const [batchList, setBatchList] = useState([])
    const [createdByList, setCreatedByList] = useState([])

    const [showFilter, setShowFilter] = useState(false)

    const [fromDate, setFromDate] = useState(today())
    const [toDate, setToDate] = useState(today())
    const [batchId, setBatchId] = useState('')
    const [locationId, setLocationId] = useState('')
    const [shedId, setShedId] = useState('')
    const [createdBy, setCreatedBy] = useState('')

    const handleFilter = () => setShowFilter(true)

    const handleFromDate = (e) => {
        setFromDate(e.target.value)
    }

    const handleToDate = (e) => setToDate(e.target.value)

    const handleBatchId = (e) => {
        setBatchId(e.target.value)
    }
    const handleLocationId = (e) => {
        setShedId('')
        setBatchId('')
        setBatchList([])
        setShedList([])
        setLocationId(e.target.value)
    }
    const handleShedId = (e) => {
        setBatchId('')
        setBatchList([])
        setShedId(e.target.value)
    }

    const handleCreatedBy = (e) => {
        setCreatedBy(e.target.value)
    }

    const getBatchList = () => {
        api.get(`batch/?shed_id=${shedId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setBatchList(data)
            }
        }).catch(error => {
            setBatchList([])
        })
    }

    const getUserList = () => {
        api.get('user/', getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setCreatedByList(data)
            }
        }).catch(error => {
            setCreatedByList([])
        })
    }

    const getExpenseList = () => {
        setLoading(true)
        api.get(`expense/?from_date=${fromDate}&to_date=${toDate}&batch_id=${batchId}&location_id=${locationId}&shed_id=${shedId}&created_by=${createdBy}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setExpenseList(data)
            }
        }).catch(error => {
            setExpenseList([])
        }).finally(() => {
            setLoading(false)
        })
    }
    const getLocationList = () => {
        api.get(`location/${user.role === 'vendor' ? `?vendor_id=${user.id}` : ''}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setLocationList(data)

            }
        }).catch(error => {
            setLocationList([])
        })
    }

    const getShedList = () => {
        api.get(`shed/?location_id=${locationId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setShedList(data)

            }
        }).catch(error => {
            setShedList([])
        })
    }

    const handleDelete = (id) => {
        api.delete(`expense/?id=${id}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { message } = response.data
                toast(message)
                setExpenseList(prev => {
                    return prev.filter(expense => {
                        return expense.id !== id
                    })
                })
            }
        }).catch(error => {

        })
    }

    useEffect(() => {
        getExpenseList()
    }, [fromDate, toDate, batchId, createdBy]) // eslint-disable-line

    useEffect(() => {
        if (user.role !== "vendor") {
            getUserList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (shedId !== "") {
            getBatchList()
        }
    }, [shedId]) // eslint-disable-line

    useEffect(() => {
        if (locationId !== '') {
            getShedList()
        }
    }, [locationId]) // eslint-disable-line

    useEffect(() => {
        getLocationList()
    }, []) // eslint-disable-line

    return <Fragment>
        {showFilter && <Modal setShowModal={setShowFilter}>
            <div className="p-2">
                <div className="form-group">
                    <label htmlFor="fromDate">From Date</label>
                    <input type="date" className="form-control" id="fromDate" onChange={handleFromDate} value={fromDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="toDate">To Date</label>
                    <input type="date" className="form-control" id="toDate" onChange={handleToDate} value={toDate} />
                </div>
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <select id="location" className="form-control" onChange={handleLocationId} value={locationId}>
                        <option value="">All</option>
                        {locationList.map((location, index) => {
                            return <Option
                                value={location.id}
                                text={location.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="shed">Shed</label>
                    <select id="shed" className="form-control" onChange={handleShedId} value={shedId}>
                        <option value="">All</option>
                        {shedList.map((shed, index) => {
                            return <Option
                                value={shed.id}
                                text={shed.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="batch">Batch</label>
                    <select id="batch" className="form-control" onChange={handleBatchId} value={batchId}>
                        <option value="">All</option>
                        {batchList.map((batch, index) => {
                            return <Option
                                value={batch.id}
                                text={batch.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>


                {(user.role === "admin" || user.role === "supervisor") && <div className="form-group">
                    <label htmlFor="createdBy">Created By</label>
                    <select id="createdBy" className="form-control" onChange={handleCreatedBy} value={createdBy}>
                        <option value="">All</option>
                        {createdByList.map((createdBy, index) => {
                            return <Option
                                value={createdBy.userId}
                                text={createdBy.name}
                                key={index}
                            />
                        })}
                    </select>
                </div>}
            </div>
        </Modal>}

        <div className="osahan-forgot">
            <div className="bg-light border-bottom p-3">
                <div className="d-flex align-items-center justify-content-between">
                    <Link className="text-primary" to="/">
                        <i className="feather-chevron-left"></i>
                        <span>Back</span>
                    </Link>
                    {(user.role === "admin" || user.role === "supervisor") && <Link to="add" className="text-primary">
                        <span>Add</span>
                        <i className="feather-chevron-right" />
                    </Link>}
                </div>
            </div>
            <div className="p-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h2>Expense</h2>
                    <button className="btn btn-primary" onClick={handleFilter}>
                        <i className="feather-filter mr-1" />
                        <span>Filter</span>
                    </button>
                </div>
                <h6> <div className="text-right">Total Amount: &#8377;{Number(expenseList.reduce((prev, current) => prev + current.amount, 0)).toFixed(2)}</div></h6>
                <div className="overflow-auto mt-4">
                    {loading
                        ? <Spinner />
                        : expenseList.length > 0
                            ? <table className="w-100 table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Batch</th>
                                        <th>Details</th>
                                        <th>Amount</th>
                                        <th>Created By</th>
                                        {(user.role === "admin" || user.role === "supervisor") && <th className="text-center" colSpan={2}>Action</th>}

                                    </tr>
                                </thead>
                                <tbody>
                                    {expenseList.map((expense, index) => {


                                        return <ExpenseBody

                                            id={expense.id}
                                            sNo={index + 1}
                                            date={expense.createdDate}
                                            batch={expense.batch.name}
                                            details={expense.details}
                                            amount={expense.amount}
                                            createdBy={expense.created.userName}
                                            key={index}
                                            onDelete={() => handleDelete(expense.id)}
                                            user={user}

                                        />

                                    })}
                                </tbody>
                            </table>
                            : <Error errorText="NO EXPENSE RECORDS FOUND" />}
                </div>
            </div>
        </div>
    </Fragment>
}

const ExpenseBody = ({ id, sNo, date, batch, createdBy, details, amount, onDelete, user }) => {
    return <tr>
        <td className="align-middle">{sNo}</td>
        <td className="align-middle text-truncate">{date}</td>
        <td className="align-middle text-capitalize">{batch}</td>
        <td className="align-middle text-capitalize " style={{ width: "100px" }}>{details}</td>
        <td className="align-middle">&#8377;{Number(amount).toFixed(2)}</td>
        <td className="align-middle text-capitalize">{createdBy}</td>
        {(user.role === "admin" || user.role === "supervisor") && <td className="align-middle text-center">
            <Link to={`edit/${id}`} className="btn btn-primary btn-sm mx-1">Edit</Link>
        </td>}
        {(user.role === "admin" || user.role === "supervisor") && <td className="align-middle text-center">
            <button className="btn btn-primary btn-sm mx-1" onClick={onDelete}>Delete</button>
        </td>}

    </tr>
}

export default Expense