import React, { useContext, Fragment, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ToastContext from '../context/ToastContext'
import { api, getAxiosConfig } from '../include/api'
import { isValidEmail, isValidUsername } from '../include/function'

const UserForm = ({ type }) => {
    const { toast } = useContext(ToastContext)
    const params = useParams()

    const [userId, setUserId] = useState('')
    const [userIdError, setUserIdError] = useState('')
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [emailId, setEmailId] = useState('')
    const [emailIdError, setEmailIdError] = useState('')
    const [role, setRole] = useState('')
    const [roleError, setRoleError] = useState('')
    const [status, setStatus] = useState('')
    const [statusError, setStatusError] = useState('')

    const handleUserId = (e) => {
        setUserIdError('')
        setUserId(String(e.target.value).toLowerCase())
    }
    const handlePassword = (e) => {
        setPasswordError('')
        setPassword(e.target.value)
    }
    const handleName = (e) => {
        setNameError('')
        setName(e.target.value)
    }
    const handleEmailId = (e) => {
        setEmailIdError('')
        setEmailId(e.target.value)
    }
    const handleRole = (e) => {
        setRoleError('')
        setRole(e.target.value)
    }
    const handleStatus = (e) => {
        setStatusError('')
        setStatus(e.target.value)
    }

    const handleSubmitUser = (e) => {
        e.preventDefault()

        let error = false

        const data = type === "ADD" ? {
            userId: String(userId).toLowerCase(),
            password: password,
            name: name,
            emailId: emailId,
            role: role,
        } : {
            name: name,
            emailId: emailId,
            role: role,
            status: status
        }

        if (type === "ADD") {
            if (data.userId === '') {
                setUserIdError("Username required")
                error = true
            } else if (!isValidUsername(data.userId)) {
                setUserIdError("Sorry, only small letters (a-z), numbers (0-9), and underscore (_) are allowed.")
                error = true
            } else if (data.userId.length < 4 || data.userId.length > 30) {
                setUserIdError("Username must be 4 to 30 characters long")
                error = true
            }

            if (data.password === '') {
                setPasswordError("Password required")
                error = true
            } else if (data.password.length < 6 || data.password.length > 8) {
                setPasswordError("Password must be 6 to 8 characters long")
                error = true
            }

        }

        if (data.name === '') {
            setNameError("Name required")
            error = true
        } else if (data.name.length > 30) {
            setNameError("Name must be within 30 characters long")
            error = true
        }

        if (data.emailId !== '' && !isValidEmail(data.emailId)) {
            setEmailIdError("Email must be a valid one")
            error = true
        }

        if (data.role === '') {
            setRoleError("Role required")
            error = true
        } else if (["admin", "supervisor", "marketing"].indexOf(data.role) === -1) {
            setRoleError("Role must be admin or supervisor or marketing only")
            error = true
        }

        if (type === "EDIT") {
            if (data.status === '') {
                setStatusError("Status required")
                error = true
            } else if (["active", "inactive"].indexOf(data.status) === -1) {
                setStatusError("Status must be active or inactive only")
                error = true
            }

        }

        if (error === false) {
            if (type === "ADD") {
                api.post('user/', data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                        setUserId('')
                        setPassword('')
                        setName('')
                        setEmailId('')
                        setRole('')
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                })
            } else if (type === "EDIT") {
                api.put(`user/?user_id=${params.userId}`, data, getAxiosConfig()).then((response) => {
                    if (response.status === 200) {
                        toast(response.data.message);
                    }
                }).catch(error => {
                    error.response
                        ? toast(error.response.data.message)
                        : toast(error.message)
                })
            }
        }
    }

    const getUser = () => {
        api.get(`user/?user_id=${params.userId}`, getAxiosConfig()).then(response => {
            if (response.status === 200) {
                const { data } = response.data
                setName(data.name)
                setRole(data.role)
                setEmailId(data.emailId)
                setStatus(data.status)
            }
        })
    }

    useEffect(() => {
        if (type === "EDIT") {
            getUser()
        }
    }, []) // eslint-disable-line

    return <div className="osahan-forgot">
        <div className="bg-light border-bottom p-3">
            <Link className="text-primary" to="/user"><i className="feather-chevron-left"></i> Back</Link>
        </div>
        <div className="p-4">
            <h2>{type === "ADD" ? "Add" : "Edit"} User</h2>
            <form className="mt-5 mb-4">

                {type === "ADD" && <Fragment>
                    <div className="form-group">
                        <label htmlFor="userId">Username</label>
                        <input type="text" className="form-control" id="userId" onChange={handleUserId} value={userId} />
                        <span className="d-inline-block text-danger">{userIdError}</span>
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input type="password" className="form-control" id="password" onChange={handlePassword} value={password} />
                        <span className="d-inline-block text-danger">{passwordError}</span>
                    </div>
                </Fragment>}

                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" onChange={handleName} value={name} />
                    <span className="d-inline-block text-danger">{nameError}</span>
                </div>

                <div className="form-group">
                    <label htmlFor="emailId">Email</label>
                    <input type="email" className="form-control" id="emailId" onChange={handleEmailId} value={emailId} />
                    <span className="d-inline-block text-danger">{emailIdError}</span>
                </div>

                <div className="form-group">
                    <label htmlFor="role">Role</label>
                    <select id="role" className="form-control" onChange={handleRole} value={role}>
                        <option value="">Select Role </option>
                        <option value="admin">Admin</option>
                        <option value="supervisor">Supervisor</option>
                        <option value="marketing">Marketing</option>
                    </select>
                    <span className="d-inline-block text-danger">{roleError}</span>
                </div>

                {type === "EDIT" && <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <select id="status" className="form-control" onChange={handleStatus} value={status}>
                        <option value="">Select Status </option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                    <span className="d-inline-block text-danger">{statusError}</span>
                </div>}

                <button className="btn btn-primary btn-lg btn-block text-uppercase" onClick={handleSubmitUser}>{type === "ADD" ? "Add" : "update"}</button>
            </form>
        </div>
    </div>
}

export default UserForm
